import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import EconomicGroup from "../modules/EconomicGroup";
import Modality from "../modules/Modality/components";
import PrincipalsPage from "../modules/Principals";
import Principal from "../modules/Principals/Principal";
import LogsReport from "../modules/Reports/LogsReport";
import ReportPrincipal from "../modules/Reports/PrincipalExpireReport";
import RatingsReport from "../modules/Reports/RatingsReport";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="modalities" />} />
      <Route path="modalities/" element={<Modality />} />
      <Route path="economic-groups/" element={<EconomicGroup />} />
      <Route path="principals/" element={<PrincipalsPage />}>
        <Route path=":principalId" element={<Principal />} />
      </Route>
      <Route path="reports/">
        <Route path="logs/" element={<LogsReport />} />
        <Route path="ratings/" element={<RatingsReport />} />
        <Route path="principals/" element={<ReportPrincipal />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
