import {
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React from "react";
import useStyles from "./styles.js";
import { v4 as uuidv4 } from 'uuid';
import { toUpper } from "lodash";
import { dominioEnum, statusEnum } from "../../../utils/enumerations"


export default function ReportTableCollapse({ data, ratings, getEconomicSectorName }) {
    const classes = useStyles();
    return (

        <Box sx={{ margin: 1 }}>
            <TableContainer className={classes.container}>
                <Table aria-label="collapsible table" className={classes.tableBox}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ flex: 1 }} className={classes.collumSt} />
                            <TableCell style={{ flex: 1, }} className={classes.collumSt}>
                                <Typography variant="span" className={classes.itemTitle}>
                                    Campo Alterado
                                </Typography>
                            </TableCell>
                            <TableCell style={{ flex: 1 }} className={classes.collumSt}>
                                <Typography variant="span" className={classes.itemTitle}>
                                    Valor Antigo
                                </Typography>
                            </TableCell>
                            <TableCell style={{ flex: 1 }} className={classes.collumSt}>
                                <Typography variant="span" className={classes.itemTitle}>
                                    Valor Novo
                                </Typography>
                            </TableCell>
                            <TableCell style={{ flex: 1 }} className={classes.collumSt}>
                                <Typography variant="span" className={classes.itemTitle}>
                                    Descrição
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <Row data={data} ratings={ratings} getEconomicSectorName={getEconomicSectorName}/>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}

function Row({ data, ratings, getEconomicSectorName }) {
    const classes = useStyles();
    changeFields(data, ratings, getEconomicSectorName);

    return (
        <>
            {data.map((item) => (
                <TableRow key={uuidv4()}>
                    <TableCell style={{ flex: 1 }} className={classes.collumSt} />
                    <TableCell style={{ flex: 1 }} className={classes.collumSt}>
                        <Typography variant="span" className={classes.itemDescription}>
                            {item.Property}
                        </Typography>
                    </TableCell>
                    <TableCell style={{ flex: 1 }} className={classes.collumSt}>
                        {item.OldValue != null ? (
                            <Typography variant="span" className={classes.itemDescription}>
                                {item.OldValue}
                            </Typography>
                        ) : ("-")}
                    </TableCell>
                    <TableCell style={{ flex: 1 }} className={classes.collumSt}>
                        {item.NewValue != null ? (
                            <Typography variant="span" className={classes.itemDescription}>
                                {item.NewValue}
                            </Typography>
                        ) : ("-")}
                    </TableCell>
                    <TableCell style={{ flex: 1 }} className={classes.collumSt}>
                        {item.Context ? (
                            <Typography variant="span" className={classes.itemDescription}>
                                {item.Context.Description}
                            </Typography>
                        ) : ("")}
                    </TableCell>
                </TableRow>
            ))}
        </>
    );
}

function changeFields(data,ratings, getEconomicSectorName) {
    const dominioOptions = dominioEnum;
    const statusOptions = statusEnum;

    const fieldmap = {
        status_i4pro : "Status",
        domain_id : "Dominio",
        limit : "Limite de Crédito Global",
        limit_portal : "Limite de Crédito",
        active_portal : "Ativar Modalidade",
        minimum_rate : "Taxa de Risco",
        maximum_duration : "Vigência Máxima",
        validity : "Validade",
        rating_agency_id : "Rating Agência",
        global_rating : "Rating Global",
        national_rating : "Rating Nacional",
        internal_rating : "Rating Interno",
        special_acceptance : "Aceitação Especial",
        special_acceptance_rate : "Taxa Aceitação Especial",
        minimum_premium : "Prêmio Mínimo",
        limit_is : "Limite Máximo de IS",
        maximum_limit_is : "Limite Máximo de Is",
        maximum_duration_days : "Vigência Máxima",
        portal_rate_percent: "% do Limite Portal",
        economic_sector_id: "Setor Econômico"
    };

    const contextmap = {
        "Group Rating" : "Grupo Rating",
        "Group Limits" : "Grupo Limites"
    };

    return data.map(item => {
        if(item.Property === "domain_id"){
            item.NewValue =  dominioOptions.find(i => i.value == item.NewValue)?.label;
            item.OldValue =  dominioOptions.find(i => i.value == item.OldValue)?.label;
        }
        if(item.Property === "status_i4pro"){
            item.NewValue =  statusOptions.find(i => i.value == item.NewValue)?.label;
            item.OldValue =  statusOptions.find(i => i.value == item.OldValue)?.label;
        }
        if(item.Property === "rating_agency_id"){
            if(ratings && ratings[0].RatingAgencies){
                item.NewValue =  ratings[0].RatingAgencies.find(i => toUpper(i.Id) === toUpper(item.NewValue))?.Name;
                item.OldValue =  ratings[0].RatingAgencies.find(i => toUpper(i.Id) === toUpper(item.OldValue))?.Name;
            }
        }

        if(item.Context != null && item.Context.Description != null){
            if(contextmap[item.Context.Description])
                item.Context.Description = contextmap[item.Context.Description]; 
        }

        if(item.Property === "economic_sector_id"){
            item.NewValue =  getEconomicSectorName(item.NewValue);
            item.OldValue =  getEconomicSectorName(item.OldValue);
        }

        const newValue = fieldmap[item.Property];
        item.Property = newValue ? newValue : item.Property;  
        return item;
    });

  }