import { Box, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

/**
 * This component renders a group of form controls
 *
 * @param {Object} props
 * @param {string} props.title title of the group
 * @param {React.ReactNode} props.children
 * @returns {React.ReactNode} A React element that renders a group of form controls.
 */
export default function FormGroup({ title = "", children }) {
  const theme = useTheme();
  return (
    <Box
      borderRadius={2}
      sx={{
        border: `1px solid ${theme.palette.primary.light}`,
      }}
    >
      {title && (
        <Box
          display="flex"
          alignItems="center"
          pl={2}
          borderRadius="8px 8px 0 0"
          height={50}
          sx={{
            backgroundColor: "#F4F4F4",
          }}
        >
          <Typography
            variant="title"
            sx={{ color: theme.palette.primary.light }}
          >
            {title}
          </Typography>
        </Box>
      )}
      <Box py={4} px={2}>
        <Stack direction={{ sm: "column", md: "row" }} gap={3} flexWrap="wrap">
          {children}
        </Stack>
      </Box>
    </Box>
  );
}
