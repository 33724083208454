import {
  AppBar,
  Toolbar,
  Button,
  Avatar,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState, useCallback, useEffect, useContext } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Box } from "@mui/system";
import LogoutIcon from "@mui/icons-material/Logout";
import logo from "../../../assets/logos/logo_branco.svg";
import HeaderLink from "../HeaderLink";
import HeaderLinkMenu from "../HeaderLinkMenu";
import "../styles/TopMenu.css";
import { AppContext } from "../../../context/context";
import { useNavigate,  useLocation } from 'react-router-dom';

const reportRoutes = [
  { name: "Logs", path: "/reports/logs" },
  { name: "Ratings", path: "/reports/ratings" },
  { name: "Tomadores a Expirar", path: "/reports/principals" },
];

const Header = (props) => {
  const { keycloak } = useKeycloak();
  const context = useContext(AppContext);
  const tokenParsed = keycloak.tokenParsed;
  
  const handleLogout = () => {
    keycloak.logout();
  };

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    
    const handleBackButton = () => {
      context.setIsChange(false);  
    };
    window.addEventListener('beforeunload', handleConfirm, { capture: true });
    window.onpopstate = (e) => {handleBackButton(e)};

    return () => {
      window.removeEventListener('beforeunload', handleConfirm, { capture: true });
    };

  }, [navigate, context, context.isChange, context.setIsChange]);

  const handleConfirm = useCallback((e) => {
    const message = 'Ë possível que haja alterações não salvas, deseja sair?';
    if (context.isChange) {  

      if (!window.confirm(message)) {
        e.preventDefault();
      }
      else {
        context.setIsChange(false);
      }
    }
  }, [context, context.isChange, context.setIsChange]);

  return (
    <>
      <AppBar position="static" sx={{ padding: "0 0 0 30px" }}>
        <Toolbar>
          <Grid container item xs={12}>
            <Grid item xs={8} display="flex" alignItems="center">
              <Box
                component="img"
                src={logo}
                className="Logo-Menu"
                alt="logo"
              />
              <Box display="flex" alignItems="center" gap="30px">
                <HeaderLink onClickHandler={handleConfirm} path="/modalities">Modalidade</HeaderLink>
                <HeaderLink onClickHandler={handleConfirm} path="/economic-groups">Grupo Econômico</HeaderLink>
                <HeaderLink onClickHandler={handleConfirm} path="/principals">Tomador</HeaderLink>
                <HeaderLinkMenu onClickHandler={handleConfirm} id="menu-reports" routes={reportRoutes} />
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              gap={3}
              style={{ color: "#fff" }}
            >
              <Box gap={2} display="flex" alignItems="center">
                <Typography variant="headerText">
                  {tokenParsed?.name ?? "Carregando nome..."}
                </Typography>
                <Avatar alt={tokenParsed?.name ?? "Usuário"} />
              </Box>
              <Button
                color="inherit"
                sx={{ textTransform: "none" }}
                startIcon={<LogoutIcon sx={{ color: "inherit" }} />}
                onClick={handleLogout}
              >
                <Typography variant="headerText">Sair</Typography>
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid container p={2}>
        {props.children}
      </Grid>
    </>
  );
};

export default Header;
