import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  smallCell: {
    width: 90,
  },
  mediumCell: {
    maxWidth: 90,
  },
  collumSt: {
    padding: theme.spacing(2),
    textAlign: 'left',
    fontWeight : "bold",
    maxWidth : "15em"
  },
  tableBox: {
    background: "#F4F4F4",
    marginTop: "25px",
    marginBottom: "25px",
  },

  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  griPd: {
    padding: 0
  },
  container: {
    maxHeight: 300
  },
  pdNone:{
    padding: 0
  },
  pdHeaderTable:{
    padding: 0,
    paddingTop : 10,
    paddingBottom : 10,
  },
  mH4:{
    minHeight:"4em"
  },
  mW20:{
    minWidth:"20em"
  },
  mW15:{
    minWidth:"15em"
  },
  w100per:{
    width:"100%"
  },
  formControl: {
    minWidth: "15em",
    maxWidth: "30em"
  },
  pdr:{
    paddingRight: "1.5em"
  },
  itemGrid2: {
    boxShadow:"none",
    maxWidth: "25em"
  },
  bsNone: {
    boxShadow:"none",
  },
  buttonSearch: {
    height: "100%",
    padding: "0px 35px !important",
    backgroundColor: `${theme.palette.secondary.main} !important`,
  },
  buttonText: {
    color: theme.palette.secondary.light,
    textTransform: "capitalize",
  },
  boxDatePicker: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    paddingTop: "20px"
  },
  erroStyle:{
    '& > div > fieldset' : {
        borderColor : "#bf2155;"
    }
  },
  noresult:{
    textAlign: "center",
    color: "darkgray"
  },
  cursorpointer : {
    cursor: 'pointer'
  },
}));

export default useStyles;
