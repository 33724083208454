import { useCallback, useEffect, useState } from "react";
import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import useLogsClient from "../../../clients/LogsClient";
import useRatingClient from "../../../clients/RatingClient";
import useEconomicSectorClient from "../../../clients/EconomicSectorClient";
import ReportTable from "./ReportTable";
import ReportPagination from "../../../components/ReportPagination";
import ReportFilterLogs from "./ReportFilterLogs";
import dayjs from 'dayjs';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function LogsReport() {
  const logsClient = useLogsClient();
  const ratingClient = useRatingClient();
  const economicSectorClient = useEconomicSectorClient();
  const [loading, setLoading] = useState(true);
  const [loadingContent, setLoadingContent] = useState(false);
  const [data, setData] = useState([]);
  const [ratings, setRatings] = useState([]);
  const [economicSector, setEconomicSector] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rows) => {
    setRowsPerPage(+rows);
  };
  const handlerGetlogsByFilter = (filter) => {
    setLoadingContent(true);
    setTimeout(async () => {

      const response = await fetchLogs(filter);
      setOtherData(response);
    }, 2000);
  };

  useEffect(() => {
    setTimeout(async () => {
      const dtEnd = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      const dtIni = dayjs(dtEnd).subtract(12, 'hour').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      const filter = {
        FromActionDate: dtIni,
        UntilActionDate: dtEnd
      }
      const response = await fetchLogs(filter);
      setData(response);
      setCount(response.length);
      
      const ratingsResponse = await fetchRatings();
      setRatings(ratingsResponse);

      const economicSectorResponse = await fetchEconomicSector();
      setEconomicSector(economicSectorResponse);

    }, 2500);
  }, []);

  useEffect(() => {
    setData(otherData);
    setCount(otherData.length);
  }, [otherData]);

  const fetchLogs = async (filter) => {
    try {
      const client = logsClient();
      const response = await client.getLogs(filter);
      return response.data.Result;

    } catch (error) {
      console.error("Error fetching logs:", error);
      const descriptions = error.response ? error.response.data.Messages.map(message => message.Description) : error.message;
      var errorMessage = error.response ? descriptions.join("\n") : descriptions;

      toast.error(errorMessage, {
        autoClose: 5000,
      });
      return [];
    }
    finally {
      setLoading(false);
      setLoadingContent(false);
    }
  };
  const fetchRatings = async () => {
    try {
      const client = ratingClient();
      const response = await client.getRatings();
      return response.data.Result;

    } catch (error) {
      console.error("Error fetching Ratings:", error);
      const descriptions = error.response ? error.response.data.Messages.map(message => message.Description) : error.message;
      var errorMessage = error.response ? descriptions.join("\n") : descriptions;
      toast.error(errorMessage, {
        autoClose: 5000,
      });
      return [];
    }
  };
  const fetchEconomicSector = async () => {
    try {
      const client = economicSectorClient();
      const response = await client.getEconomicSector();
      return response.data.Result;

    } catch (error) {
      console.error("Error fetching EconomicSector:", error);
      const descriptions = error.response ? error.response.data.Messages.map(message => message.Description) : error.message;
      var errorMessage = error.response ? descriptions.join("\n") : descriptions;
      toast.error(errorMessage, {
        autoClose: 5000,
      });
      return [];
    }
  };

  /*
  *Foi feito desta forma, pois cada linha gerana no log estava levando o objeto de economicSector, 
  *desta forma levamos a assiantura da func e resolvemos através da chamada.
  *Futuramente implementar para os ratings
  */
  const getEconomicSectorName = useCallback((id) => {
    return id ? economicSector.find(x => String(x.Id).toLowerCase() === id).Name : null
  });

  return (
    <Grid container display="flex" flexDirection="column" p="40px">
      <ToastContainer
        pauseOnHover={false}
        draggable={false}
        autoClose={0}
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography variant="titleSection" fontWeight={600}>
          Logs
        </Typography>
      </Grid>
      {loading ? (
        <Stack gap={4} marginTop={4}>
          <Skeleton
            width="100%"
            height="180px"
            animation="wave"
            variant="rectangular"
          />
          <Skeleton
            width="100%"
            height="580px"
            animation="wave"
            variant="rectangular"
          />
        </Stack>
      ) : (
        <>
          <ReportFilterLogs
            handlerGetlogsByFilter={handlerGetlogsByFilter}
          />
          <ReportPagination
            count={count}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {!loadingContent ? (
            <ReportTable
              data={data.length !== 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : []}
              ratings={ratings}
              getEconomicSectorName={getEconomicSectorName}
            />
          ) : (
            <Skeleton
              width="100%"
              height="580px"
              animation="wave"
              variant="rectangular"
            />
          )
          }
        </>
      )}

    </Grid>

  );
}
