import Keycloak from "keycloak-js";

const keycloakConfig = {
  url: process.env.REACT_APP_AUTH_URL,
  realm: "seguradora",
  clientId: "guardian-frontend",
  onLoad: "check-sso",
};

const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
