import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import keycloak from "./keycloak";
import theme from "./theme/theme";
import { SharedContext } from "./context/context";

const root = ReactDOM.createRoot(document.getElementById("root"));

const eventLogger = (event) => {
  if (!keycloak.authenticated) {
    keycloak.login();
  }
};

const tokenLogger = (token) => {};

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    onEvent={eventLogger}
    onTokens={tokenLogger}
    initOptions={{
      checkLoginIframe: false,
    }}
  >
    <SharedContext>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
    </SharedContext>
  </ReactKeycloakProvider>
);
