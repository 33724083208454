import { Grid, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { formatCurrency } from "../../utils/formatFunction";
import BoxContent from "../BoxContent";
import BrlCurrencyComponent from "../CurrencyInput";
import LabeledValue from "../LabeledValue";
import PercentageField from "../PercentageInput";

const CreditLimit = ({
  limits,
  isPortalPercentRequired,
  hasPortalPercentError,
  onChangeGlobalLimit = (value) => {},
  onChangePortalPercentage = (value) => {},
}) => {
  const globalExposure = limits?.Exposure ? limits?.Exposure : 0;
  const [globalLimit, setGlobalLimit] = useState(0);
  const [globalAvailable, setGlobalAvailable] = useState(0);
  const [portalLimitPercent, setPortalLimitPercent] = useState(0);
  const [portalLimit, setPortalLimit] = useState(0);
  const [portalAvailable, setPortalAvailable] = useState(0);

  useEffect(() => {
    if (limits) {
      setGlobalLimit(limits?.Limit);
      setPortalLimitPercent(limits?.PortalRatePercent);
    }
  }, [limits]);

  useEffect(() => {
    setGlobalAvailable(globalLimit ? globalLimit - globalExposure : 0);
  }, [globalLimit, globalExposure]);

  useEffect(() => {
    setPortalLimit(globalLimit * (portalLimitPercent / 100) ?? 0);
  }, [globalLimit, portalLimitPercent]);

  useEffect(() => {
    setPortalAvailable(portalLimit ? portalLimit - globalExposure : 0);
  }, [portalLimit, globalExposure]);

  const handleGlobalLimitChange = (value) => {
    setGlobalLimit(value);
    onChangeGlobalLimit(value);
  };

  const handlePortalLimitPercentChange = (value) => {
    setPortalLimitPercent(value);
    onChangePortalPercentage(value);
  };

  const inputStyle = {
    borderRadius: "4px",
    color: "rgba(19, 18, 18, 0.6)",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 400,
  };

  return (
    <BoxContent title="Limite de Crédito">
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        columnSpacing={4}
        rowSpacing={3}
        pt={5}
      >
        <Grid item xs={4}>
          <BrlCurrencyComponent
            fullWidth
            value={globalLimit || null}
            onChange={(e, value) => handleGlobalLimitChange(value)}
            label="Limite de Crédito Global"
            InputLabelProps={{
              style: {
                fontSize: 13,
                top: 3,
              },
            }}
            InputProps={{
              style: inputStyle,
              inputProps: {
                maxLength: 21,
              },
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <LabeledValue label="Exposição">
            {formatCurrency(globalExposure)}
          </LabeledValue>
        </Grid>
        <Grid item xs={4}>
          <LabeledValue label="Disponível">
            {formatCurrency(globalAvailable)}
          </LabeledValue>
        </Grid>
        <Grid item xs={4}>
          <PercentageField
            fullWidth
            value={portalLimitPercent || 0}
            onChange={(e, value) => handlePortalLimitPercentChange(value)}
            maximumFractionDigits={2}
            label="% do Limite Portal"
            required={isPortalPercentRequired}
            error={hasPortalPercentError}
            helperText={hasPortalPercentError ? "Percentual inválido." : null}
            InputLabelProps={{
              style: {
                fontSize: 13,
                top: 3,
              },
            }}
            InputProps={{
              style: inputStyle,
              inputProps: {
                maxLength: 6,
              },
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <LabeledValue label="Limite de Crédito Global Portal">
            {formatCurrency(portalLimit)}
          </LabeledValue>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <LabeledValue label="Disponível Portal">
            {formatCurrency(portalAvailable)}
          </LabeledValue>
        </Grid>
      </Grid>
    </BoxContent>
  );
};

CreditLimit.propTypes = {
  limits: PropTypes.object.isRequired,
  isPortalPercentRequired: PropTypes.bool,
  hasPortalPercentError: PropTypes.bool,
  onChangeGlobalLimit: PropTypes.func,
  onChangePortalPercentage: PropTypes.func,
};

export default CreditLimit;
