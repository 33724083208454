import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { formatCnpj } from "../../utils/formatFunction";

function EmptyList({ text }) {
  return (
    <Box width={1} padding={2}>
      <Typography align="center" fontSize={18} color="GrayText">
        {text ?? "Nada para exibir!"}
      </Typography>
    </Box>
  );
}

export default function CustomersList({
  customers = [],
  color = "secondary.greenHaze",
  emptyListText = null,
}) {
  if (!customers?.length) return <EmptyList text={emptyListText} />;

  return (
    <List
      dense
      sx={{
        width: 1,
        pt: 3,
        color,
      }}
    >
      {customers.map((customer) => (
        <ListItem
          key={customer.Id}
          sx={{
            mb: 1,
            borderStyle: "solid",
            borderRadius: 1,
            borderWidth: 2,
            borderColor: color,
          }}
          secondaryAction={
            customer.Link && (
              <Link to={customer.Link}>
                <Tooltip title="Abrir">
                  <IconButton edge="end" aria-label="open" sx={{ color }}>
                    <OpenInNewIcon />
                  </IconButton>
                </Tooltip>
              </Link>
            )
          }
        >
          <ListItemText
            primaryTypographyProps={{
              variant: "takerText",
            }}
            secondaryTypographyProps={{
              variant: "takerSecondaryText",
            }}
            primary={customer.Name}
            secondary={
              customer.DocumentNumber
                ? "CNPJ: " + formatCnpj(customer.DocumentNumber)
                : null
            }
          />
        </ListItem>
      ))}
    </List>
  );
}
