import { TextField } from "@mui/material";
import IntlCurrencyInput from "react-intl-currency-input";

const PercentageField = ({ value, maximumFractionDigits, ...props }) => {
  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: maximumFractionDigits,
          useGrouping: false,
        },
      },
    },
  };

  return (
    <IntlCurrencyInput
      {...props}
      autoSelect
      currency="BRL"
      config={currencyConfig}
      value={value}
      component={TextField}
    />
  );
};

export default PercentageField;
