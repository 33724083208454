import {
  Avatar,
  Box,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import PropTypes from "prop-types";
import icon from "../../assets/img/icon_group.svg";
import IOSSwitch from "../IOSSwitch";

const BoxContent = ({
  title,
  hasBackground,
  hasSwitch,
  switchTitle,
  checked,
  onToggle,
  iconComponent,
  children,
}) => {
  const theme = useTheme();
  return (
    <Grid
      container
      py={2.5}
      width={1}
      borderRadius={2}
      sx={{
        backgroundColor:
          hasBackground === false
            ? "transparent"
            : theme.palette.secondary.light,
      }}
    >
      <Stack direction="row" width={1}>
        <Box sx={{ translate: "-16px" }}>
          {iconComponent ? (
            <Avatar sx={{ bgcolor: "#78D2C4" }}>{iconComponent}</Avatar>
          ) : (
            <Avatar src={icon} />
          )}
        </Box>
        <Typography variant="titleBoxContent" flexGrow={1}>
          {title}
        </Typography>
        {hasSwitch && (
          <FormControlLabel
            label={switchTitle}
            labelPlacement="start"
            sx={{ mx: 2 }}
            control={
              <IOSSwitch sx={{ m: 1 }} checked={checked} onChange={onToggle} />
            }
          />
        )}
      </Stack>
      <Box width={1} px={2.5}>
        {children}
      </Box>
    </Grid>
  );
};

BoxContent.propTypes = {
  title: PropTypes.string.isRequired,
  hasBackground: PropTypes.bool,
  hasSwitch: PropTypes.bool,
  switchTitle: PropTypes.string,
  checked: PropTypes.bool,
  onToggle: PropTypes.func,
  iconComponent: PropTypes.element,
  children: PropTypes.node,
};

export default BoxContent;
