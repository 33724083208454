import { Link } from "react-router-dom";
import { Button, Typography } from "@mui/material";

export default function HeaderLink({ onClickHandler, path, children }) {
  return (
    <Link to={path} style={{ textDecoration: "none", color: "#fff" }}>
      <Button onClick={onClickHandler} sx={{ textTransform: "none" }} color="inherit">
        <Typography variant="headerText">{children}</Typography>
      </Button>
    </Link>
  );
}
