import {
  Button,
  Chip,
  Grid,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckIcon from "../../../assets/img/check-icon.svg";
import EditIcon from "../../../assets/img/edit-icon.svg";
import useModalityClient from "../../../clients/ModalityClient";
import BrlCurrencyComponent from "../../../components/CurrencyInput";
import MyModal from "../../../components/Modal";
import PageContainer from "../../../components/PageContainer";
import useStyles from "./styles";
import { AppContext } from "../../../context/context";

const Modality = () => {
  const modalityClient = useModalityClient();
  const context = useContext(AppContext);
  const classes = useStyles();
  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [otherData, setOtherData] = useState([]);
  const [data, setData] = useState([]);
  const [updatedData, setUpdatedData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [showToastError, setShowToastError] = useState(false);

  useEffect(() => {
    const fetchModalities = async () => {
      try {
        const client = modalityClient();
        const response = await client.getModalityGroups();

        const data = response.data.Result
        .filter(item =>  ![4, 5, 6].includes(item.Id)) 
        .map((item) => ({
          id: item.Id,
          name: item.Description,
          maximumLimitIS: item.MaximumLimitIs,
          maximumDurationDays: item.MaximumDurationDays,
        }));
        setData(data);
        setUpdatedData(data);
        setOtherData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching modalities:", error);

        const descriptions = error.response.data.Messages.map(
          (message) => message.Description
        );
        var errorMessage = descriptions.join("\n");

        toast.error(errorMessage, {
          autoClose: 5000,
        });
      }
    };

    fetchModalities();
  }, [modalityClient]);

  const handleChangeMaximumLimitIS = (value, index) => {
    const newErrors = [...errors];
    newErrors[index] = {
      ...newErrors[index],
      maximumLimitIS: value <= 0 || value === "0",
    };

    const newUpdatedData = updatedData.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          maximumLimitIS: value,
        };
      }
      return item;
    });

    setBlocked(
      newErrors.some((e) => e?.maximumDurationDays || e?.maximumLimitIS)
    );
    setErrors(newErrors);
    setUpdatedData(newUpdatedData);
    context.setIsChange(true);
  };

  const handleChangeMaximumDurationDays = (value, index) => {
    const regex = /^[0-9\b]+$/;
    if (!(value === "" || regex.test(value))) {
      return;
    }

    const newErrors = [...errors];
    newErrors[index] = { ...newErrors[index], maximumDurationDays: value <= 0 };

    const newUpdatedData = updatedData.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          maximumDurationDays: value,
        };
      }
      return item;
    });

    setBlocked(
      newErrors.some((e) => e?.maximumDurationDays || e?.maximumLimitIS)
    );
    setErrors(newErrors);
    setUpdatedData(newUpdatedData);
    context.setIsChange(true);
  };

  useEffect(() => {
    if (showToastError) {
      toast.error("Favor revisar o formulário.", {
        autoClose: 5000,
        onClose: () => setShowToastError(false),
      });
    }
  }, [showToastError]);

  const handleClickSave = () => {
    if (blocked) {
      setShowToastError(true);
      return;
    }
    setModalConfirm(true);
  };

  const handleSubmit = async () => {
    setIsSending(true);
    setModalConfirm(false);
    const toastId = toast.info("Enviando dados...");

    const payload = updatedData.map((item) => {
      const { id, maximumLimitIS, maximumDurationDays } = item;

      return {
        idModality: id,
        maximumLimitIS:
          maximumLimitIS !== otherData.find((d) => d.id === id).maximumLimitIS
            ? maximumLimitIS
            : null,
        maximumDurationDays:
          maximumDurationDays !==
          otherData.find((d) => d.id === id).maximumDurationDays
            ? maximumDurationDays
            : null,
      };
    });

    modalityClient()
      .saveModality(payload)
      .then(() => {
        toast.dismiss(toastId);
        setIsSending(false);
        setModalSuccess(true);
        setModalConfirm(false);
        context.setIsChange(false);
        
        /*Ë necessário zerar o payload, pois se o usuário alterar algo novamente na mesma tela sem ter feito um refresh 
        aquela informação da requisição anterior ainda estará disponível sendo enviada para o backend e gerando inconsistência no log de ações*/
        handlerResetPayload(payload);
      })
      .catch((error) => {
        setModalConfirm(false);
        setIsSending(false);
        let errorMessage =
          "Falha ao enviar os dados. Por favor, tente novamente.";
        const descriptions = error.response.data.Messages.map(
          (message) => message.Description
        );
        errorMessage = descriptions.join("\n");
        toast.update(toastId, {
          render: errorMessage,
          type: toast.TYPE.ERROR,
          autoClose: 0,
        });
      });
  };

  const handlerResetPayload = (payload) => {
    const newData = otherData.map((item) => {
      const mxLimits = payload.find(
        (d) => d.idModality === item.id
      ).maximumLimitIS;
      const mxDurationDays = payload.find(
        (d) => d.idModality === item.id
      ).maximumDurationDays;

      return {
        id: item.id,
        name: item.name,
        maximumLimitIS:
          mxLimits !== null ? parseFloat(mxLimits) : item.maximumLimitIS,
        maximumDurationDays:
          mxDurationDays !== null ? mxDurationDays : item.maximumDurationDays,
      };
    });

    setOtherData(newData);
  };

  const handleClose = () => {
    setModalConfirm(false);
    setModalSuccess(false);
  };

  return (
    <PageContainer title="Modalidade">
      {loading ? (
        <Stack direction="column" spacing={3}>
          {Array.from({ length: 8 }).map((_, index) => (
            <Skeleton key={index} variant="rounded" height={83} />
          ))}
        </Stack>
      ) : (
        <Grid width={1}>
          {data.map((modality, index) => {
            return (
              <Grid
                item
                xs={12}
                mt={2}
                p={2}
                display="flex"
                justifyContent="space-between"
                container
                className={classes.gridContainer}
                key={modality.id}
              >
                <Grid
                  item
                  xs={3}
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  className={classes.gridModality}
                >
                  <Typography variant="poppins15">Modalidade</Typography>
                  <Tooltip title={modality.name} placement="top">
                    <Chip label={modality.name} className={classes.chip} />
                  </Tooltip>
                </Grid>
                <Grid
                  item
                  xs={4}
                  display="flex"
                  alignItems="center"
                  className={classes.gridLimitIS}
                >
                  <Typography variant="poppins15">
                    Limite Máximo de IS
                  </Typography>
                  <BrlCurrencyComponent
                    className={classes.textField}
                    sx={{ m: 1, width: 160 }}
                    InputProps={{
                      style: {
                        height: 35,
                        borderRadius: "4px",
                        color: "rgba(19, 18, 18, 0.6)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                      },
                      inputProps: {
                        maxLength: 21,
                      },
                    }}
                    key={modality.id}
                    value={updatedData[index].maximumLimitIS}
                    error={Boolean(errors[index]?.maximumLimitIS)}
                    onChange={(event, value) =>
                      handleChangeMaximumLimitIS(value, index)
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={3}
                  display="flex"
                  alignItems="center"
                  className={classes.gridMaxValidity}
                >
                  <Typography variant="poppins15">Vigência Máxima</Typography>
                  <TextField
                    className={classes.textField}
                    InputProps={{
                      style: {
                        height: 35,
                        borderRadius: "4px",
                        maxWidth: "80px",
                        width: "100%",
                        color: "rgba(19, 18, 18, 0.6)",
                        fontSize: "14px",
                        fontFamily: "Roboto",
                        fontWeight: 400,
                      },
                      inputProps: {
                        min: 0,
                        max: 9999,
                        maxLength: 4,
                      },
                    }}
                    key={modality.id}
                    value={updatedData[index].maximumDurationDays}
                    error={Boolean(errors[index]?.maximumDurationDays)}
                    onChange={(event) =>
                      handleChangeMaximumDurationDays(event.target.value, index)
                    }
                  />
                </Grid>
              </Grid>
            );
          })}

          <Stack direction="row-reverse" pt={3}>
            <Button
              className={classes.button}
              onClick={() => handleClickSave()}
              disabled={isSending}
            >
              <Typography variant="text2" className={classes.textButton}>
                Salvar
              </Typography>
            </Button>
          </Stack>

          <MyModal
            title="Alteração grupos"
            description="A alteração do Limite Máximo de IS e a Vigência Máxima serão replicadas para todos os Grupos Econômicos e Tomadores. Deseja prosseguir com a alteração?"
            hasCancelButton={true}
            editIcon={true}
            icon={EditIcon}
            open={modalConfirm}
            onClose={handleClose}
            onConfirm={() => handleSubmit()}
          />

          <MyModal
            title="Dados salvos com sucesso!"
            open={modalSuccess}
            onClose={handleClose}
            icon={CheckIcon}
            onConfirm={handleClose}
            closeButton={handleClose}
          />
        </Grid>
      )}

      <ToastContainer pauseOnHover={false} draggable={false} autoClose={0} />
    </PageContainer>
  );
};

export default Modality;
