import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";

export default function StatusChip({ enabled }) {
  const theme = useTheme();
  const enabledColor = "rgba(57, 158, 41, 0.82)";
  const disabledColor = "rgba(152, 152, 152, 0.82)";
  const backgroundColor = enabled ? enabledColor : disabledColor;
  return (
    <Box borderRadius={3} px={2} sx={{ backgroundColor }}>
      <Typography color={theme.palette.secondary.light}>
        {enabled ? "Ativo" : "Inativo"}
      </Typography>
    </Box>
  );
}
