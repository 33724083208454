import { Autocomplete, FormControl, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import FormGroup from "../FormGroup";

const ratingTypes = {
  GLOBAL: "Global",
  NATIONAL: "National",
};

function filterGlobalRatingOptions(ratings) {
  const globalRatings = ratings?.find(
    (rating) => rating.Type === ratingTypes.GLOBAL
  );
  return globalRatings?.Values ?? [];
}

function filterNationalRatingOptions(ratings) {
  const nationalRatings = ratings?.find(
    (rating) => rating.Type === ratingTypes.NATIONAL
  );
  return nationalRatings?.Values ?? [];
}

export default function AgencyRating({
  options = [],
  value,
  onChange = () => {},
  readOnly,
}) {
  const [rating, setRating] = useState(null);
  const [globalRatingOptions, setGlobalRatingOptions] = useState([]);
  const [nationalRatingOptions, setNationalRatingOptions] = useState([]);

  const handleAgencyChange = (agency) => {
    const newRating = {
      agency,
      globalRating: null,
      nationalRating: null,
    };
    setRating(newRating);
    change(newRating);
  };

  const handleGlobalRating = (globalRating) => {
    const newRating = { ...rating, globalRating };
    setRating(newRating);
    change(newRating);
  };

  const handleNationalRating = (nationalRating) => {
    const newRating = { ...rating, nationalRating };
    setRating(newRating);
    change(newRating);
  };

  const change = (newRating) => {
    onChange({
      RatingAgencyId: newRating.agency?.Id,
      GlobalRating: newRating.globalRating,
      NationalRating: newRating.nationalRating,
    });
  };

  useEffect(() => {
    const defaultRatingAgency = options?.find(
      (a) => a.Id === value?.RatingAgencyId
    );
    setRating({
      agency: defaultRatingAgency,
      globalRating: value?.GlobalRating,
      nationalRating: value?.NationalRating,
    });

    const defaultGlobalRatingOptions = filterGlobalRatingOptions(
      defaultRatingAgency?.Ratings
    );
    setGlobalRatingOptions(defaultGlobalRatingOptions);

    const defaultNationalRatingOptions = filterNationalRatingOptions(
      defaultRatingAgency?.Ratings
    );
    setNationalRatingOptions(defaultNationalRatingOptions);
  }, [options, value]);

  useEffect(() => {
    if (rating?.agency) {
      const globalOptions = filterGlobalRatingOptions(rating.agency.Ratings);
      setGlobalRatingOptions(globalOptions);
      const nationalOptions = filterNationalRatingOptions(
        rating.agency.Ratings
      );
      setNationalRatingOptions(nationalOptions);
    }
  }, [rating]);

  return (
    <FormGroup title="Agência">
      <FormControl sx={{ flexGrow: 1, minWidth: "200px" }}>
        <Autocomplete
          id="agencies"
          options={options}
          getOptionLabel={(option) => option.Name}
          value={rating?.agency ?? null}
          onChange={(_, newValue) => handleAgencyChange(newValue)}
          readOnly={readOnly}
          on
          renderInput={(params) => (
            <TextField
              {...params}
              label="Agência"
              InputProps={{
                ...params.InputProps,
                style: { paddingTop: 10, paddingBottom: 10 },
              }}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              placeholder="Selecione uma opção..."
            />
          )}
        />
      </FormControl>
      <FormControl sx={{ flexGrow: 1, minWidth: "200px" }}>
        <Autocomplete
          id="global-ratings"
          options={globalRatingOptions}
          value={rating?.globalRating ?? null}
          disabled={!rating?.agency || !globalRatingOptions?.length}
          onChange={(_, newValue) => handleGlobalRating(newValue)}
          isOptionEqualToValue={(option, value) => option === value}
          readOnly={readOnly}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Rating Global"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              placeholder={
                !rating?.agency || !globalRatingOptions?.length
                  ? null
                  : "Selecione uma opção..."
              }
            />
          )}
        />
      </FormControl>
      <FormControl sx={{ flexGrow: 1, minWidth: "200px" }}>
        <Autocomplete
          id="national-ratings"
          options={nationalRatingOptions}
          isOptionEqualToValue={(option, value) => option === value}
          value={rating?.nationalRating ?? null}
          onChange={(_, newValue) => handleNationalRating(newValue)}
          disabled={!rating?.agency || !nationalRatingOptions?.length}
          readOnly={readOnly}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Rating Nacional"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              placeholder={
                !rating?.agency || !nationalRatingOptions?.length
                  ? null
                  : "Selecione uma opção..."
              }
            />
          )}
        />
      </FormControl>
    </FormGroup>
  );
}
