import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  Stack,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import usePrincipalClient from "../../../clients/PrincipalClient";
import BoxContent from "../../../components/BoxContent";

export default function SearchPrincipal({ children }) {
  const theme = useTheme();
  const principalClient = usePrincipalClient();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (searchQuery) {
      const client = principalClient();
      setSearchLoading(true);
      client
        .getPrincipals(searchQuery)
        .then((res) => {
          if (res.data.StatusCode === 200) {
            setOptions([
              ...res.data.Result.map((principal) => ({
                id: `${principal.Id}`,
                label: principal.Name,
              })),
            ]);
          }
        })
        .catch((error) => {
          console.error("Error fetching principals:", error);
          const descriptions = error.response.data.Messages.map(
            (message) => message.Description
          );
          var errorMessage = descriptions.join("\n");
          toast.error(errorMessage, { autoClose: 5000 });
        })
        .finally(() => setSearchLoading(false));
    } else {
      setOptions([]);
    }
  }, [principalClient, searchQuery]);

  const debounceSearch = debounce((value) => {
    if (value?.length >= 3) setSearchQuery(value);
    else setSearchQuery("");
  }, 500);

  return (
    <BoxContent title="Buscar Tomador" iconComponent={<SearchIcon />}>
      <Stack direction="row" gap={2.5} alignItems="center" mt={4}>
        <FormControl fullWidth>
          <Autocomplete
            id="principal-autocomplete"
            options={options}
            value={selectedOption}
            onChange={(event, value) => setSelectedOption(value)}
            onInputChange={(event, value) => debounceSearch(value)}
            getOptionLabel={(option) => option.label}
            getOptionKey={(option) => option.id}
            noOptionsText="Nenhum tomador corresponde à busca"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Tomador"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {searchLoading && <CircularProgress size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </FormControl>
        <Link to={selectedOption?.id ?? "/principals"}>
          <Button
            color="primary"
            disableElevation
            variant="contained"
            size="large"
            sx={{
              padding: "14px 14px",
              color: theme.palette.secondary.light,
              textTransform: "capitalize",
              backgroundColor: theme.palette.secondary.main,
              "&:hover": {
                backgroundColor: theme.palette.secondary.main,
              },
            }}
          >
            Buscar
          </Button>
        </Link>
      </Stack>
      {children}
    </BoxContent>
  );
}
