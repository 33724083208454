export const formatUTCDateToBRDate = (dateString) => {
  const date = new Date(dateString);
  const options = {
    timeZone: "America/Sao_Paulo",
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  const formattedDate = date.toLocaleDateString("pt-BR", options);
  return formattedDate;
}

export const formatCurrency = (value) => {
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
}

export const formatPercentage = (value) => {
  if (typeof value !== "number") {
    return value;
  }

  return `${value}%`;
}

export const onlyNumbers = (value) => {
  return value.replace(/[^0-9]/g, "");
};

/**
 * Aplica uma máscara de CNPJ a um documento
 * @param {string?} document
 * @returns {string?} documento formatado no formato 00.000.000/0000-00
 */
export const formatCnpj = (document) => {
  if (!document) return document;
  const cnpjSize = 14;
  if (document?.length < cnpjSize) document = document.padStart(cnpjSize, "0");
  return document.replace(
    /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
    "$1.$2.$3/$4-$5"
  );
};

/**
 * Aplica uma máscara de CPF a um documento
 * @param {string?} document
 * @returns {string?} documento formatado no formato 000.000.000-00
 */
export const formatCpf = (document) => {
  if (!document) return document;
  const cpfSize = 11;
  if (document?.length < cpfSize) document = document.padStart(cpfSize, "0");
  return document.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
};

/**
 * Aplica uma máscara de CNPJ ou CPF ao documento de acordo com o tamanho do mesmo
 * @param {string?} document
 * @returns {string?} documento formatado
 */
export const formatDocument = (document) => {  
  if (document && document.length <= 11) {
    return formatCpf(document);
  } else if (document && document.length > 11 && document.length <= 14) {
    return formatCnpj(document);
  } else {
    return document;
  }
};
