import { Box, Button, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/styles";
import PropTypes from "prop-types";

function LoadingButton({ loading, onClick, children }) {
  const theme = useTheme();
  return (
    <Box sx={{ m: 1, position: "relative" }}>
      <Button
        disabled={loading}
        onClick={onClick}
        color="primary"
        disableElevation
        size="large"
        variant="contained"
        sx={{
          padding: "14px 45px",
          color: theme.palette.secondary.light,
          textTransform: "capitalize",
          borderRadius: 2,
          backgroundColor: theme.palette.primary.steelBlue,
          "&:hover": {
            backgroundColor: theme.palette.primary.steelBlue,
          },
        }}
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress
          color="primary"
          size={42}
          lar
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-22px",
            marginLeft: "-22px",
          }}
        />
      )}
    </Box>
  );
}

LoadingButton.propTypes = {
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default LoadingButton;
