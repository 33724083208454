import PropTypes from "prop-types";
import { createContext, useState } from "react";

export const AppContext = createContext();

export function SharedContext({ children }) {
  const [isChange, setIsChange] = useState(false);
  
  return (
    <AppContext.Provider value={{ isChange, setIsChange }}>
      {children}
    </AppContext.Provider>
  );
}

SharedContext.propTypes = {
  children: PropTypes.node.isRequired,
};