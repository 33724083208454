import StarHalfRoundedIcon from "@mui/icons-material/StarHalfRounded";
import { Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import useRatingClient from "../../../clients/RatingClient";
import BoxContent from "../../../components/BoxContent";
import AgencyRating from "../AgencyRating";
import InternalRating from "../InternalRating";

export default function Rating({ 
    rating, 
    onChange = () => {},
    readOnly,
  }) {

  const ratingClient = useRatingClient();
  const [ratingAgencyOptions, setRatingAgencyOptions] = useState(null);
  const [internalRatingOptions, setInternalRatingOptions] = useState(null);

  useEffect(() => {
    const client = ratingClient();
    client
      .getRatings()
      .then((response) => {
        const ratings = response.data.Result[0];
        setRatingAgencyOptions(ratings?.RatingAgencies);
        setInternalRatingOptions(ratings?.InternalRating);
      })
      .catch((error) => {
        console.error("Error fetching ratings: ", error);
      });
  }, [ratingClient]);

  const handleAgencyChange = useCallback(
    (agencyRating) => {
      onChange({
        ...rating,
        ...agencyRating,
      });
    },
    [rating, onChange]
  );

  const handleInternalRatingChange = useCallback(
    (internalRating) => {
      onChange({
        ...rating,
        ...internalRating,
      });
    },
    [rating, onChange]
  );

  return (
    <BoxContent title="Rating" iconComponent={<StarHalfRoundedIcon />}>
      <Grid container mt={2} spacing={2} columns={{ xs: 4, sm: 4, md: 12 }}>
        <Grid item xs={8}>
          {ratingAgencyOptions && (
            <AgencyRating
              options={ratingAgencyOptions}
              value={rating}
              onChange={handleAgencyChange}
              readOnly={readOnly}
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {internalRatingOptions && (
            <InternalRating
              options={internalRatingOptions}
              value={rating}
              onChange={handleInternalRatingChange}
              readOnly={readOnly}
            />
          )}
        </Grid>
      </Grid>
    </BoxContent>
  );
}
