import { Divider, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import StatusChip from "../../StatusChip";

export default function CustomerSummaryHeader({ customer }) {
  const theme = useTheme();
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      paddingX={3}
      paddingY={1.5}
      sx={{
        backgroundColor: "white",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
      }}
    >
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        alignItems="start"
        justifyContent="flex-start"
        spacing={{ xs: 0, sm: 1 }}
        color={theme.palette.primary.light}
        divider={<Divider orientation="vertical" flexItem />}
      >
        <Typography variant="title">{customer.Name}</Typography>
        {customer.DocumentNumber && (
          <Typography variant="title">{customer.DocumentNumber}</Typography>
        )}
        
        {customer.EconomicGroup &&  
        <>
          <Typography variant="title">Grupos: </Typography>
          {customer.EconomicGroup.map(e => (
            <Typography variant="title">{e.Name}</Typography>
        ))}
        </>
        }

      </Stack>

      <Stack direction="row" spacing={0.5} alignItems="center">
        <Typography variant="span" color={theme.palette.primary.light}>
          Status:
        </Typography>
        <StatusChip enabled={customer.RegistrationStatus === true} />
      </Stack>
    </Stack>
  );
}
