import { Box, Divider, InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import BoxContent from "../BoxContent";
import PercentageField from "../PercentageInput";

const SpecialAcceptance = ({
  enabled,
  rate,
  onChange,
  hasAcceptanceRateError,
  specialHookReset
}) => {
  const [value, setValue] = useState({
    enabled,
    rate,
  });

  const handleToggle = (newEnabled) => {
    const newValue = {
      ...value,
      enabled: newEnabled,
    };
    setValue(newValue);
    onChange(newValue);
  };

  const handleChange = (newRate) => {
    const newValue = {
      ...value,
      rate: newRate,
    };
    setValue(newValue);
    onChange(newValue);
  };

  useEffect(() => {
    setValue({
      enabled,
      rate,
    });
  }, [specialHookReset])

  return (
    <BoxContent
      title="Aceitação Especial"
      hasSwitch={true}
      switchTitle="Aceitação Especial:"
      checked={value.enabled ?? false}
      onToggle={() => handleToggle(!value.enabled)}
    >
      {value.enabled && (
        <Box sx={{ marginTop: "20px" }} width={1} gap={3}>
          <Divider />
          <PercentageField
            label="Taxa"
            sx={{ marginTop: 3, width: { xs: 1, sm: 1, md: "200px" } }}
            InputProps={{
              inputProps: {
                max: 100,
                maxLength: 6,
              },
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            maximumFractionDigits={2}
            required={value.enabled}
            helperText={hasAcceptanceRateError ? "Valor inválido." : null}
            error={hasAcceptanceRateError}
            value={value.rate || 0}
            onChange={(_, value) => handleChange(value)}
          />
        </Box>
      )}
    </BoxContent>
  );
};

SpecialAcceptance.propTypes = {
  enabled: PropTypes.bool.isRequired,
  rate: PropTypes.number,
  onChange: PropTypes.func,
  hasAcceptanceRateError: PropTypes.bool,
};

export default SpecialAcceptance;
