import { useCallback } from "react";
import http from "../api/http";

const usePrincipalClient = () => {
  const getPrincipalExpirings = useCallback(async () => {
    return http.get(`/principals/expiring`);
  }, []);

  const getPrincipalExpiringsDocument = useCallback(async () => {
    return http.get(`/principals/expiring/document`);
  }, []);

  const getPrincipals = useCallback(async (name) => {
    return http.get(`/principals`, {
      params: { name },
    });
  }, []);

  const getPrincipalById = useCallback(async (id) => {
    return http.get(`/principals/${id}`);
  }, []);

  const updatePrincipal = useCallback(async (id, principal) => {
    return http.put(`/principals/${id}`, principal);
  }, []);

  return useCallback(
    () => ({
      getPrincipalExpiringsDocument,
      getPrincipalExpirings,
      getPrincipals,
      getPrincipalById,
      updatePrincipal,
    }),
    [
      getPrincipalExpiringsDocument,
      getPrincipalExpirings,
      getPrincipals,
      getPrincipalById,
      updatePrincipal,
    ]
  );
};

export default usePrincipalClient;
