import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    backgroundColor: "#ffffff",
    borderRadius: 1,

    "@media (max-width: 425px)": {
      flexDirection: "column",
      gap: "10px",
    },
  },

  gridModality: {
    gap: "30px",

    "@media (max-width: 425px)": {
      justifyContent: "flex-start",
      maxWidth: "100% !important",
    },
  },

  gridLimitIS: {
    gap: "20px",

    "@media (max-width: 80px)": {
      justifyContent: "flex-start",
      maxWidth: "100% !important",
    },
  },

  gridMaxValidity: {
    gap: "20px",

    "@media (max-width: 425px)": {
      justifyContent: "flex-start",
      maxWidth: "100% !important",
    },
  },

  chip: {
    minWidth: 100,

    "& .MuiChip-label": {
      fontFamily: "Roboto",
      fontWeight: 700,
      fontSize: "14px",
      color: "#142A70",
      padding: "0 16px",
    },

    "&.MuiChip-root": {
      backgroundColor: theme.palette.primary.lightGrayishBlue,
    },
  },

  button: {
    "&.MuiButton-root": {
      padding: "13px 45px",
      backgroundColor: theme.palette.primary.steelBlue,
      borderRadius: "8px",

      "&:hover": {
        backgroundColor: theme.palette.primary.steelBlue,
        opacity: 0.8,
      },

      "&:disabled": {
        backgroundColor: theme.palette.secondary.lightGray,
      }
    },
  },

  textButton: {
    color: theme.palette.secondary.light,
    textTransform: "capitalize",
  },

  textField: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.lightSeaGreen,
    },

    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.lightSeaGreen,
    },
  },
  formControl: {
    minWidth: 120,
  },

  containerTomador: {
    background: "#F4F4F4",
    borderRadius: "8px",
    border: "1px solid #ccc",
  },

  tomadorTable: {
    background: "#F4F4F4"
  },

  tomadorRow: {
    background: "#FFF"
  },

  boxHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "12px 30px",
    background: "#fff",
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px'
  },

  rightSide: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },

  boxStatusInfo: {
    background: "rgba(57, 158, 41, 0.82)",
    borderRadius: "12px",
    padding: "0px 15px",
  },

  textTomador: {
    color: theme.palette.primary.light,
  },

  textStatus: {
    color: theme.palette.primary.light,
  },

  textInfo: {
    color: theme.palette.secondary.light,
  },

  itemContainer: {
    padding: "24px 0",
  },

  item: {
    paddingLeft: "32px",
    display: "flex",
    flexDirection: "column",
  },

  itemTitle: {
    color: theme.palette.primary.darkBlueGray,
    alignContent: "center",
  },

  itemDescription: {
    color: theme.palette.primary.coolBlueGray,
  },

  buttonText: {
    color: theme.palette.secondary.light,
    textTransform: "capitalize",
  },

  textMenuItem: {
    color: theme.palette.primary.coolBlueGray,
  },

  btSearch: {
    width: "100px"
  },
  searchIcon: {
    fontSize: "10", 
    marginRight: "8",
  },

  smallCell: {
    width: 90,
  },
  mediumCell: {
    maxWidth: 90,
  },
  


}));

export default useStyles;