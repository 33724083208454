import { Grid } from "@mui/material";
import LabeledValue from "../../LabeledValue";

function CustomerDetailGrid({ children }) {
  return (
    <Grid container paddingX={4} paddingY={3} spacing={4}>
      {children.map((child, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
}

export default function CustomerSummaryDetails({ customer }) {
  return (
    <CustomerDetailGrid>
      <LabeledValue label="Limite de Crédito Global">
        {customer.Limits.Limit}
      </LabeledValue>
      <LabeledValue label="Exposição">{customer.Limits.Exposure}</LabeledValue>
      <LabeledValue label="Disponível">
        {customer.Limits.Available}
      </LabeledValue>
      <LabeledValue label="Última Atualização i4pro">
        {customer.LastUpdateI4Pro}
      </LabeledValue>
      <LabeledValue label="Data de Validade">
        {customer.Limits.Validity}
      </LabeledValue>
      <LabeledValue label="Taxa Mínima">
        {customer.Limits.MinimumRate}%
      </LabeledValue>
      <LabeledValue label="Prêmio Mínimo">
        {customer.Limits.MinimumPremium}
      </LabeledValue>
    </CustomerDetailGrid>
  );
}
