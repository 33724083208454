import { Autocomplete, FormControl, TextField } from "@mui/material";
import { useState } from "react";
import FormGroup from "../FormGroup";

export default function InternalRating({
  options = [],
  value,
  onChange = () => {},
  readOnly,
}) {
  const [rating, setRating] = useState({
    internalRating: value?.InternalRating,
  });

  const handleInternalRatingChange = (internalRating) => {
    setRating({ ...rating, internalRating });
    onChange({
      InternalRating: internalRating,
    });
  };

  return (
    <FormGroup title="Interno">
      <FormControl sx={{ flexGrow: 1, minWidth: "200px" }}>
        <Autocomplete
          id="internal-ratings"
          options={options}
          isOptionEqualToValue={(option, value) => option === value}
          value={rating.internalRating ?? null}
          onChange={(_, newRating) => handleInternalRatingChange(newRating)}
          readOnly={readOnly}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Rating Interno"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              placeholder="Selecione uma opção..."
            />
          )}
        />
      </FormControl>
    </FormGroup>
  );
}
