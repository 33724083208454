import { useCallback } from "react";
import http from "../api/http";

const useRatingClient = () => {
  const getEconomicSector = useCallback(async () => {
    return http.get(`/economic-sector`);
  }, []);

  return useCallback(
    () => ({
        getEconomicSector,
    }),
    [getEconomicSector]
  );
};

export default useRatingClient;
