import { Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/styles";
import PropTypes from "prop-types";

function LabeledValue({ label, children }) {
  const theme = useTheme();
  return (
    <Stack direction="column">
      <Typography variant="span" color={theme.palette.primary.darkBlueGray}>
        {label}:
      </Typography>
      <Typography
        variant="description"
        color={theme.palette.primary.coolBlueGray}
      >
        {children}
      </Typography>
    </Stack>
  );
}

LabeledValue.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default LabeledValue;
