import { CircularProgress, Stack } from "@mui/material";
import { useCallback, useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CheckIcon from "../../../assets/img/check-icon.svg";
import EditIcon from "../../../assets/img/edit-icon.svg";
import usePrincipalClient from "../../../clients/PrincipalClient";
import CreditLimit from "../../../components/CreditLimit";
import CustomerDetail from "../../../components/CustomerDetail";
import CustomerSummary from "../../../components/CustomerSummary";
import LoadingButton from "../../../components/LoadingButton";
import MyModal from "../../../components/Modal";
import ModalityGroupLimits from "../../../components/ModalityGroupLimits";
import SpecialAcceptance from "../../../components/SpecialAcceptance";
import Rating from "../../Ratings/Rating";
import { AppContext } from "../../../context/context";

const defaultPayload = {
  limit: null,
  minimumPremium: null,
  portalRatePercent: null,
  minimumRate: null,
  specialAcceptance: null,
  specialAcceptanceRate: null,
  validity: null,
  domain: null,
  status: null,
  rating: null,
  modalityLimits: [],
};

export default function Principal() {
  const context = useContext(AppContext);
  const { principalId } = useParams();
  const principalClient = usePrincipalClient();
  const [principal, setPrincipal] = useState(null);
  const [hookPrincipal, setHookPrincipal] = useState(null);

  const [globalLimit, setGlobalLimit] = useState(0);
  const [minimumRate, setMinimumRate] = useState(0);

  const [portalPercentageError, setPortalPercentageError] = useState(false);
  const [minimumRateError, setMinimumRateError] = useState(false);
  const [economicSectorError, setEconomicSectorError] = useState(false);
  const [hasEconomicSector, setHasEconomicSector] = useState(false);
  const [acceptanceRateError, setAcceptanceRateError] = useState(false);
  const [isSending, setSending] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);

  const [errors, setErrors] = useState([]);

  const [payloadPost, setPayloadPost] = useState({ ...defaultPayload });

  useEffect(() => {
    const client = principalClient();

    client
      .getPrincipalById(principalId)
      .then((result) => {
        setPrincipal(result.data.Result[0]);
        setHookPrincipal(principalId);
        setHasEconomicSector(result.data.Result[0].EconomicSector?.Id ? true : false);
        context.setIsChange(false);  
      })
      .catch((err) => {
        console.error("Error getting principal: ", err);
      });
  }, [principalId, principalClient]);

  useEffect(() => {
    setGlobalLimit(principal?.Limits?.Limit ?? 0);
    setMinimumRate(principal?.Limits?.MinimumRate ?? 0);
  }, [principal]);

  const resetPayload = useCallback(() => {
    setPayloadPost({ ...defaultPayload });
  }, [setPayloadPost]);

  function handleSave() {
    if (blocked) {
      toast.error("Por favor, revise o formulário.", {
        autoClose: 5000,
        pauseOnFocusLoss: false,
      });
      return;
    }
    setShowConfirmDialog(true);
  }

  async function savePost() {
    const toastId = toast.info("Enviando dados...");
    setSending(true);

    const client = principalClient();
    try {
      await client.updatePrincipal(principalId, payloadPost);
      toast.dismiss(toastId);
      setSending(false);
      setShowSuccessDialog(true);
      resetPayload();
      context.setIsChange(false);
    } catch (error) {
      let errorMessage =
        "Falha ao enviar os dados. Por favor, tente novamente.";
      const descriptions = error.response.data.Messages.map(
        (message) => message.Description
      );
      errorMessage = descriptions.join("\n");
      toast.update(toastId, {
        render: errorMessage,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        pauseOnFocusLoss: false,
      });
    } finally {
      setSending(false);
    }
  }

  const updatePost = useCallback(
    (key, obj) => {
      let payLoad = payloadPost;
      payLoad[key] = obj;
      setPayloadPost(payLoad);
      context.setIsChange(true);
    },
    [payloadPost, setPayloadPost, context.setIsChange]
  );

  const handleValidation = (value, modality, type) => {
    const newErrors = [...errors];
    switch (type) {
      case "minimumPremium":
        const validMinimumPremium = value >= principal?.Limits.MinimumPremium;

        newErrors[modality.GroupModalityId] = {
          ...newErrors[modality.GroupModalityId],
          minimumPremium: !validMinimumPremium,
        };
        break;

      case "minimumRate":
        const validMinimumRate =
          value > 0 && value <= 100.0 && value >= minimumRate;
        newErrors[modality.GroupModalityId] = {
          ...newErrors[modality.GroupModalityId],
          minimumRate: !validMinimumRate,
        };
        break;

      case "limitPortal":
        const validLimitPortal = value > 0 && value <= globalLimit;
        newErrors[modality.GroupModalityId] = {
          ...newErrors[modality.GroupModalityId],
          limitPortal: !validLimitPortal,
        };
        break;

      case "limitIS":
        const validLimitIS = value > 0;
        newErrors[modality.GroupModalityId] = {
          ...newErrors[modality.GroupModalityId],
          limitIs: !validLimitIS,
        };
        break;

      case "maximumDuration":
        const validMaximumDuration = value > 0;
        newErrors[modality.GroupModalityId] = {
          ...newErrors[modality.GroupModalityId],
          maximumDuration: !validMaximumDuration,
        };
        break;

      case "activePortal":
        newErrors[modality.GroupModalityId] = {
          ...newErrors[modality.GroupModalityId],
          limitPortal: false,
          limitIs: false,
          maximumDuration: false,
        };
        break;

      default:
        break;
    }
    setBlocked(
      newErrors.some((e) => {
        return (
          e?.maximumDuration ||
          e?.limitIs ||
          e?.limitPortal ||
          e?.limitErp ||
          e?.minimumRate ||
          e?.minimumPremium
        );
      })
    );

    setErrors(newErrors);
  };

  const getErrorProps = (id, type) => {
    switch (type) {
      case "minimumPremium":
        return {
          error: errors[id]?.minimumPremium,
          required: errors[id]?.minimumPremium,
        };

      case "minimumRate":
        return {
          error: errors[id]?.minimumRate,
          required: errors[id]?.minimumRate,
        };

      case "limitErp":
        return {
          error: errors[id]?.limitErp,
          required: errors[id]?.limitErp,
        };

      case "limitPortal":
        return {
          error: errors[id]?.limitPortal,
          required: errors[id]?.limitPortal,
        };

      case "limitIs":
        return {
          error: errors[id]?.limitIs,
          required: errors[id]?.limitIs,
        };

      case "maximumDuration":
        return {
          error: errors[id]?.maximumDuration,
          required: errors[id]?.maximumDuration,
        };

      default:
        return {
          error: false,
          required: false,
        };
    }
  };

  const handleLimitPortalPercentageChange = (newPortalPercentage) => {
    updatePost("portalRatePercent", newPortalPercentage);
    if (newPortalPercentage > 0 && newPortalPercentage <= 100) {
      setPortalPercentageError(false);
      setBlocked(false);
      return;
    }
    setPortalPercentageError(true);
    setBlocked(true);
  };

  const handleRatingChanges = useCallback(
    (value) => {
      updatePost("rating", value);
      setPrincipal({
        ...principal,
        Rating: value,
      });
    },
    [updatePost, principal]
  );

  const handleDetailChanges = useCallback((value) => {
    const hasError = value.minimumRate <= 0 || value.minimumRate > 100;
    setMinimumRateError(hasError);
    setBlocked(hasError);

    if(hasEconomicSector){
      const hasError = value.economicSectorId == null || value.economicSectorId == "";
      setBlocked(hasError);
      setEconomicSectorError(hasError);
    }
    else{
      setEconomicSectorError(false);
    }
  }, [hasEconomicSector]);

  const handleSpecialAcceptanceChanges = useCallback(
    (value) => {
      updatePost("specialAcceptance", value.enabled);
      updatePost("specialAcceptanceRate", value.enabled ? value.rate : null);
      const validRate = !value.enabled || (value.rate > 0 && value.rate <= 100);
      setAcceptanceRateError(!validRate);
      setBlocked(!validRate);
    },
    [updatePost]
  );

  if (!principal) {
    return (
      <Stack direction="row" justifyContent="center">
        <CircularProgress size={40} />
      </Stack>
    );
  }

  return (
    <>
      <Stack direction="column" gap={4}>
        <CustomerSummary customer={principal} />
        {principal && (
          <Rating 
            rating={principal.Rating} 
            onChange={handleRatingChanges} 
            readOnly={principal.EconomicGroup ? true : false}
            />
        )}
        <CreditLimit
          limits={principal?.Limits}
          isPortalPercentRequired={true}
          hasPortalPercentError={portalPercentageError}
          onChangeGlobalLimit={(newLimit) => updatePost("limit", newLimit)}
          onChangePortalPercentage={handleLimitPortalPercentageChange}
        />

        <CustomerDetail
          title="Detalhe do Tomador"
          value={{
            minimumPremium: principal.Limits?.MinimumPremium,
            minimumRate: principal.Limits?.MinimumRate,
            validity: principal.Limits?.Validity,
            domainId: principal.Domain,
            statusId: principal.StatusI4Pro,
            economicSectorId: principal?.EconomicSector?.Id,
          }}
          onChange={handleDetailChanges}
          updatePost={updatePost}
          isMinimumRateRequired={true}
          minimumRateError={minimumRateError}
          economicSectorError={economicSectorError}
          specialHookReset={hookPrincipal}
        />

        <SpecialAcceptance
          enabled={principal.Limits?.SpecialAcceptance}
          rate={principal.Limits?.SpecialAcceptanceRate}
          hasAcceptanceRateError={acceptanceRateError}
          onChange={handleSpecialAcceptanceChanges}
          specialHookReset={hookPrincipal}
        />

        <ModalityGroupLimits
          modalityLimits={principal.Limits?.ModalityLimits ?? []}
          minimumPremiumGE={principal.Limits?.MinimumPremium}
          handleValidation={handleValidation}
          getErrorProps={getErrorProps}
          updatePost={updatePost}
        />

        <Stack direction="row-reverse">
          <LoadingButton loading={isSending} onClick={handleSave}>
            Salvar
          </LoadingButton>
        </Stack>

        {/*<BoxContent title="Grupos Econômicos">
          <CustomersList
            customers={principal.EconomicGroup}
            emptyListText="Nenhuma associação com grupo econômico."
          />
        </BoxContent>*/}
      </Stack>

      <MyModal
        title="Alteração Tomador"
        description="Deseja prosseguir com a alteração?"
        hasCancelButton={true}
        editIcon={true}
        icon={EditIcon}
        open={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={async () => {
          setShowConfirmDialog(false);
          await savePost();
        }}
      />

      <MyModal
        title="Dados do tomador atualizados com sucesso!"
        icon={CheckIcon}
        open={showSuccessDialog}
        onClose={() => setShowSuccessDialog(false)}
        onConfirm={() => setShowSuccessDialog(false)}
        on
      />

      <ToastContainer pauseOnHover={false} draggable={false} autoClose={0} />
    </>
  );
}
