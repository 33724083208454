import { useState } from "react";
import { Grid, MenuItem, Pagination, Select, Typography } from "@mui/material";

const options = [
  { value: "5", label: "5" },
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "Todos", label: "Todos" },
];

const ReportPagination = ({
  count = 10,
  onPageChange = (newPage) => {},
  onRowsPerPageChange = (rows) => {},
}) => {

  const [page, setPage] = useState(1);
  const [selectedPage, setSelectedPage] = useState("10");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (newPage) => {
    setPage(newPage);
    onPageChange(newPage - 1);
  };

  const handleChangeRowsPerPage = (rows) => {
    setSelectedPage(rows);
    const rowsPerPage = rows === "Todos" ? count : parseInt(rows, 10);
    setRowsPerPage(rowsPerPage);
    handleChangePage(1);
    onRowsPerPageChange(rowsPerPage);
  };

  return (
    <Grid container spacing={1} flexDirection="row" mt="20px" mb="10px">
      <Grid item xs>
        <Select
          size="small"
          labelId="select-label"
          id="select"
          value={selectedPage}
          onChange={(event) => handleChangeRowsPerPage(event.target.value)}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs="auto">
        <Pagination
          count={count ? Math.ceil(count / rowsPerPage) : 1}
          page={page}
          variant="outlined"
          shape="rounded"
          onChange={(_, page) => handleChangePage(page)}
        />
      </Grid>
      <Grid item xs>
        <Typography align="right">
          <strong>{count}</strong>  Registros
        </Typography>
      </Grid>
    </Grid>
  );
}
export default ReportPagination;