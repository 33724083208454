import {
  TextField,
  Typography,
  Box,
  InputAdornment,
  Grid,
  Checkbox,
  Paper,
} from "@mui/material";
import BrlCurrencyComponent from "../../CurrencyInput";
import PercentageInput from "../../PercentageInput";
import { onlyNumbers } from "../../../utils/formatFunction";

function RowCell({ children, weight, ...props }) {
  return (
    <Grid
      item
      display="flex"
      alignItems="start"
      justifyContent="center"
      xs={weight}
      {...props}
    >
      {children}
    </Grid>
  );
}

const ModalityGroupRow = ({
  modalidade,
  modalidadeValues,
  minimumPremiumGE,
  handleValidation = (value, modality, type) => {},
  getErrorProps = (id, type) => {},
  updateArrayModality,
}) => {
  const { error: minimumPremiumError, required: minimumPremiumRequired } =
    getErrorProps(modalidade.Id, "minimumPremium");
  const { error: minimumRateError, required: minimumRateRequired } =
    getErrorProps(modalidade.Id, "minimumRate");
  const { error: limitPortalError, required: limitPortalRequired } =
    getErrorProps(modalidade.Id, "limitPortal");
  const { error: limitISError, required: limitISRequired } = getErrorProps(
    modalidade.Id,
    "limitIs"
  );
  const { error: maximumDurationError, required: maximumDurationRequired } =
    getErrorProps(modalidade.Id, "maximumDuration");

  const inputStyle = {
    height: 35,
    borderRadius: "4px",
    color: "rgba(19, 18, 18, 0.6)",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 400,
  };

  return (
    <Paper variant="outlined">
      <Box borderRadius={1} py={1.5}>
        <Grid container columns={7} columnSpacing={2}>
          <RowCell weight={1.5}>
            <Typography
              variant="poppins15"
              textAlign="center"
              alignSelf="center"
            >
              {modalidade.Description}
            </Typography>
          </RowCell>
          <RowCell weight={1}>
            <BrlCurrencyComponent
              value={modalidadeValues?.LimitPortal || 0}
              onFocus={(e, value) => {
                handleValidation(value, modalidadeValues, "limitPortal");
              }}
              onChange={(e, value) => {
                updateArrayModality(modalidade.Id, "LimitPortal", value);
                handleValidation(value, modalidadeValues, "limitPortal");
              }}
              error={limitPortalError}
              helperText={limitPortalError ? "Valor inválido." : null}
              required={limitPortalRequired}
              InputProps={{
                style: inputStyle,
                inputProps: {
                  maxLength: 21,
                },
              }}
            />
          </RowCell>
          <RowCell weight={1}>
            <BrlCurrencyComponent
              value={modalidadeValues?.LimitIs || 0}
              onChange={(e, value) => {
                updateArrayModality(modalidade.Id, "LimitIs", value);
                handleValidation(value, modalidadeValues, "limitIS");
              }}
              error={limitISError}
              helperText={limitISError ? "Valor inválido." : null}
              required={limitISRequired}
              InputProps={{
                style: inputStyle,
                inputProps: {
                  maxLength: 21,
                },
              }}
            />
          </RowCell>
          <RowCell weight={1}>
            <TextField
              InputProps={{
                style: inputStyle,
                inputProps: {
                  min: 0,
                  max: 9999,
                  maxLength: 4,
                },
              }}
              value={modalidadeValues?.MaximumDuration || 0}
              onChange={(e, value) => {
                updateArrayModality(
                  modalidade.Id,
                  "MaximumDuration",
                  onlyNumbers(e.target.value)
                );
                handleValidation(
                  e.target.value,
                  modalidadeValues,
                  "maximumDuration"
                );
              }}
              error={maximumDurationError}
              helperText={maximumDurationError ? "Valor inválido." : null}
              required={maximumDurationRequired}
              placeholder="0"
            />
          </RowCell>
          <RowCell weight={1}>
            <BrlCurrencyComponent
              value={
                modalidadeValues?.MinimumPremium
                  ? modalidadeValues?.MinimumPremium > 0
                    ? modalidadeValues?.MinimumPremium
                    : minimumPremiumGE
                  : 0
              }
              onChange={(e, value) => {
                updateArrayModality(modalidade.Id, "minimumPremium", value);
                //Regra que valida e impede o update caso o premioMínimo for menor que o estabelecido no grupo
                handleValidation(value, modalidadeValues, "minimumPremium");
                //props.handleValidation(value, props.modalidadeValues, "minimumPremium");
              }}
              error={minimumPremiumError}
              helperText={minimumPremiumError ? "Valor inválido." : null}
              required={minimumPremiumRequired}
              InputProps={{
                style: inputStyle,
                inputProps: {
                  maxLength: 13,
                },
              }}
            />
          </RowCell>
          <RowCell weight={1}>
            <PercentageInput
              maximumFractionDigits={3}
              InputProps={{
                style: inputStyle,
                inputProps: {
                  maxLength: 6,
                },
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              value={modalidadeValues?.MinimumRate || 0}
              error={minimumRateError}
              helperText={minimumRateError ? "Valor inválido." : null}
              required={minimumRateRequired}
              onFocus={(e, value) => {
                handleValidation(value, modalidadeValues, "minimumRate");
              }}
              onChange={(e, value) => {
                updateArrayModality(modalidade.Id, "MinimumRate", value);
                handleValidation(value, modalidadeValues, "minimumRate");
              }}
            />
          </RowCell>
          <RowCell weight={0.5}>
            <Checkbox
              id="chkActive"
              checked={modalidadeValues?.ActivePortal || false}
              onChange={(e) => {
                updateArrayModality(
                  modalidade.Id,
                  "ActivePortal",
                  e.target.checked
                );

                if (!e.target.checked) {
                  handleValidation(
                    !e.target.checked,
                    modalidadeValues,
                    "activePortal"
                  );
                }
              }}
            />
          </RowCell>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ModalityGroupRow;
