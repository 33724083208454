import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Stack,
  TextField,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import BoxContent from "../../../components/BoxContent";
import CustomerSummary from "../../../components/CustomerSummary";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useGrupoEconomicoClient from "../../../clients/GrupoEconomicoClient";

const SearchEconomicGroup = ({ economicGroup, onGroupSelected }) => {
  const theme = useTheme();
  const economicGroupClient = useGrupoEconomicoClient();
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    if (searchQuery) {
      const client = economicGroupClient();
      setSearchLoading(true);
      client
        .searchEconomicGroup(searchQuery)
        .then((res) => {
          if (res.data.StatusCode === 200) {
            setOptions([
              ...res.data.Result.map((group) => ({
                id: `${group.Id}`,
                label: group.Name,
              })),
            ]);
          }
        })
        .catch((error) => {
          console.error("Error fetching options:", error);
          const descriptions = error.response.data.Messages.map(
            (message) => message.Description
          );
          var errorMessage = descriptions.join("\n");
          toast.error(errorMessage, { autoClose: 5000 });
        })
        .finally(() => setSearchLoading(false));
    } else {
      setOptions([]);
    }
  }, [economicGroupClient, searchQuery]);

  const debouncedSearch = debounce((value) => {
    if (value?.length >= 3) setSearchQuery(value);
    else setSearchQuery("");
  }, 500);

  return (
    <BoxContent title="Buscar Grupo Econômico">
      <Stack width={1} pt={5} direction="row" spacing={2.5}>
        <FormControl fullWidth>
          <Autocomplete
            id="economic-group"
            options={options}
            value={selectedOption}
            onChange={(event, value) => setSelectedOption(value)}
            onInputChange={(event, value) => debouncedSearch(value)}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Grupo econômico"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {searchLoading && <CircularProgress size={20} />}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </FormControl>

        <Button
          onClick={() => onGroupSelected(selectedOption)}
          color="primary"
          disableElevation
          size="large"
          variant="contained"
          sx={{
            padding: "14px 14px",
            color: theme.palette.secondary.light,
            textTransform: "capitalize",
            backgroundColor: theme.palette.secondary.main,
            "&:hover": {
              backgroundColor: theme.palette.secondary.main,
            },
          }}
        >
          Buscar
        </Button>
      </Stack>

      {economicGroup && (
        <Box width={1} pt={3}>
          <CustomerSummary customer={economicGroup} />
        </Box>
      )}
    </BoxContent>
  );
};

export default SearchEconomicGroup;
