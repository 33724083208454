import { useCallback } from "react";
import http from "../api/http";

const useModalityClient = () => {
  const saveModality = useCallback(async (body) => {
    return http.post("/modality-groups", body);
  }, []);

  const getModalityGroups = useCallback(async () => {
    return http.get("/modality-groups");
  }, []);

  return useCallback(
    () => ({
      saveModality,
      getModalityGroups,
    }),
    [saveModality, getModalityGroups]
  );
};

export default useModalityClient;
