import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";

export default function ReportTable({ data }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="table">
        <TableHead>
          <TableRow sx={{ backgroundColor: grey[100] }}>
            <TableCell>
              <Typography variant="span">Id</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="span">Nome do Grupo</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="span">Agência</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="span">Rating Global</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="span">Rating Nacional</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="span">Rating Interno</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <Row key={row.Id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function Row({ row }) {
  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          <Typography variant="span">{row.Id}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="description">{row.Name}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="description">{row.AgencyName}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="description">{row.GlobalRating}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="description">{row.NationalRating}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="description">{row.InternalRating}</Typography>
        </TableCell>
      </TableRow>
    </>
  );
}
