const dominioEnum = [
    { label: "", value: null },
    { label: 'Corretor', value: 1 },
    { label: 'Tomador', value: 2 },
    { label: 'Corretor e Tomador', value: 4 },
    { label: 'Outros', value: 8 },
  ];
  const statusEnum = [
    { label: "", value: null },
    { label: 'Aprovado', value: 1601 },
    { label: 'Negado', value: 1602 },
    { label: 'Suspenso', value: 1603 },
    { label: 'Expirado', value: 1604 },
    { label: 'Em Cadastro', value: 1606 },
    { label: 'Em Aprovação', value: 1607 },
  ];


  export { dominioEnum, statusEnum };