import { LinearProgress, Typography } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import "./App.css";
import Header from "./modules/Header/components";
import AppRoutes from "./routes";

function App() {
  const { initialized } = useKeycloak();

  if (!initialized) {
    return (
      <div className="App">
        <LinearProgress />
        <Typography mt={2} textAlign="center">
          Carregando...
        </Typography>
      </div>
    );
  }

  return (
    <div className="App">
      <Header>
        <AppRoutes />
      </Header>
    </div>
  );
}

export default App;
