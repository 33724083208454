import { createTheme } from "@mui/material/styles"; // A custom theme for this app

const theme = createTheme({
  palette: {
    primary: {
      main: process.env.REACT_APP_MAIN_COLOR,
      light: "#465584",
      dark: "#102360",
      darkBlueColor: '#142A70',
      darkGray: '#111111',
      blueGray: '#7E8BB4',
      darkBlueGray: '#3F4159',
      coolBlueGray: '#6E7191',
      lightGrayishBlue: '#D1D5E0',
      steelBlue: '#465584',
    },
    secondary: {
      main: "#63C2B6",
      light:"#ffffff",
      lightSeaGreen: "#5BCAB9",
      carrotOrange: '#ED8936',
      greenHaze: '#25AF36',
      kellyGreen: '#3CB728',
      silver: '#C8C8C8',
      manatee: '#909090',
      fiord: '#494949',
      lightGray: '#D1D1D1',
      shadeLightGray: '#E3E4E7',
      torchRed: '#B72828'
    },
  },
  typography: {
    titleSection: {
      fontFamily: 'Roboto',
      fontSize: 32,
    },

    body2: {
      fontSize: 12,
    },

    title: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '16px'
    },

    span: {
      fontFamily: 'Poppins',
      fontWeight: 700,
      fontSize: '14px'
    },

    headerText: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '16px'
    },

    description: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: '14px'
    },

    text: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '14px'
    },

    text2: {
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '16px'
    },

    rectangleText: {
      fontFamily: 'Roboto',
      fontWeight: 700,
      fontSize: '13px'
    },

    titleBoxContent: {
      fontFamily: 'Poppins',
      fontWeight: 500,
      fontSize: '24px',
    },

    poppins15: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '15px',
    },

    textModal: {
      fontFamily: 'Poppins',
      fontWeight: 600,
      fontSize: '20px',
    },

    takerText: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '14px',
    },
    takerSecondaryText: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: '12px',
    },
  },
});

export default theme;
