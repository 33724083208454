import { useCallback } from "react";
import http from "../api/http";

const useRatingClient = () => {
  const getRatings = useCallback(async () => {
    return http.get(`/ratings`);
  }, []);

  return useCallback(
    () => ({
      getRatings,
    }),
    [getRatings]
  );
};

export default useRatingClient;
