import { useState } from "react";
import { Box, Grid, TextField, Paper, Autocomplete, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Button, Typography, Collapse } from "@mui/material";
import { styled } from '@mui/material/styles';
import BoxContent from "../../../components/BoxContent";
import useStyles from "./styles.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
    display: "flex",
    width: "100%",
    float: "left"
}));

const typeOptions = [
    { label: "", value: null },
    { label: 'Atualização', value: "Update" },
    { label: 'Inclusão', value: "Add" },
    { label: 'Exclusão', value: "Delete" }
];
const actionOptions = [
    { label: "", value: null },
    { label: 'Modalidade', value: "Modality" },
    { label: 'Grupo Econômico', value: "EconomicGroup" },
    { label: 'Tomador', value: "Principal" },
];
const filter = {
    User: null,
    Action: null,
    Target: null,
    ContextDescription: null,
    FromActionDate: null,
    UntilActionDate: null
};

export default function ReportFilterLogs({
    handlerGetlogsByFilter
}) {
    const [userName, setUserName] = useState('');
    const [selectedType, setSelectedType] = useState(null);
    const [selectedTarget, setSelectedTarget] = useState(null);
    const [description, setDescription] = useState('');
    const [dateType, setDateType] = useState('24H');
    const [valueIni, setValueIni] = useState(null);
    const [valueEnd, setValueEnd] = useState(null);
    const [isDateTimePickerVisible, setIsDateTimePickerVisible] = useState(false);
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [showToastError, setShowToastError] = useState(false);
    const [hasErrorDataPicker, setHasErrorDataPicker] = useState(false);
    const [hasError, setHasError] = useState(false);

    //Filtros
    const handlerDatapicker = (e, value) => {
        handlerDatapickerShow(e, value);
        setDateType(value);
    };
    const handlerDatapickerShow = (e, value) => {
        if (value ==="CUSTOM") {
            setIsDateTimePickerVisible(true);
        }
        else {
            filter.FromActionDate = null;
            filter.UntilActionDate = null;
            setIsDateTimePickerVisible(false);
            setValueIni(null);
            setValueEnd(null);
            setHasErrorDataPicker(false);
        }
    };
    const handlerDatapickerIni = (value, validate) => {
        if (validate.validationError == null) {
            const dt = dayjs(value.$d).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            filter.FromActionDate = dt;
            setValueIni(value);
        }
    };
    const handlerDatapickerEnd = (value, validate) => {
        if (validate.validationError == null) {
            const dt = dayjs(value.$d).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
            filter.UntilActionDate = dt;
            setValueEnd(value);
        }
    };
    const handlerUserNameChange = (e, value) => {
        filter.User = value;
        setUserName(value);
    };
    const handlerActionChange = (e, obj) => {
        if (obj != null) {
            filter.Action = obj.value;
            setSelectedType(obj);
        }
        else {
            filter.Action = null;
            setSelectedType(null);
        }
    };
    const handlerTargetChange = (e, obj) => {
        if (obj != null) {
            filter.Target = obj.value;
            setSelectedTarget(obj);
        }
        else {
            filter.Target = null;
            setSelectedTarget(null);
        }
    };
    const handlerDescriptionChange = (e, value) => {
        filter.ContextDescription = value;
        setDescription(value);
    };
    const handlerSearch = () => {
        var err = false;
        dateType === "CUSTOM" ? err = handlerValidateDatePicker() : getDateByPeriod();
        
        if (!hasError && !err)
            handlerGetlogsByFilter(filter);

    };
    const handlerValidateDatePicker = () => {
        if (valueIni && valueEnd && valueIni < valueEnd) {
            setHasErrorDataPicker(false);
            return false;
        }
        else {
            setHasErrorDataPicker(true);
            toast.error("A Data Final Precisa Ser Superior a Data Inicial", {
                autoClose: 5000,
                onClose: () => setShowToastError(false),
            });
            return true;
        }
    };
    const getDateByPeriod = () => {
        var dtIni;
        var dtEnd;
        switch (dateType) {
            case "30M":
                dtEnd = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                dtIni = dayjs(dtEnd).subtract(30, 'minute').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                setHasError(false);
                break;
            case "1H":
                dtEnd = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                dtIni = dayjs(dtEnd).subtract(1, 'hour').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                setHasError(false);
                break;
            case "3H":
                dtEnd = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                dtIni = dayjs(dtEnd).subtract(3, 'hour').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                setHasError(false);
                break;
            case "12H":
                dtEnd = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                dtIni = dayjs(dtEnd).subtract(12, 'hour').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                setHasError(false);
                break;
            case "24H":
                dtEnd = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                dtIni = dayjs(dtEnd).subtract(24, 'hour').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                setHasError(false);
                break;
            case "3D":
                dtEnd = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                dtIni = dayjs(dtEnd).subtract(3, 'day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                setHasError(false);
                break;
            case "7D":
                dtEnd = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                dtIni = dayjs(dtEnd).subtract(7, 'day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                setHasError(false);
                break;
            case "30D":
                dtEnd = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                dtIni = dayjs(dtEnd).subtract(30, 'day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                setHasError(false);
                break;
            case "90D":
                dtEnd = dayjs(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                dtIni = dayjs(dtEnd).subtract(90, 'day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
                setHasError(false);
                break;
            default:
                setHasError(true);
                toast.error("Insira Um Período Válido", {
                    autoClose: 5000,
                    onClose: () => setShowToastError(false),
                });
                break;
        }
        if (!hasError) {
            filter.FromActionDate = dtIni;
            filter.UntilActionDate = dtEnd;
        }
    };

    return (
        <>
            <BoxContent title="Filtros" hasSwitch={true} checked={open} onToggle={(e, value) => setOpen(value)}>
                <Collapse in={open} timeout="auto" unmountOnExit className={classes.w100per}>
                    <Box
                        width={1}
                        style={{
                            display: "flex",
                            gap: "20px",
                            alignItems: "center",
                            paddingTop: "40px",
                        }}
                    >

                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
                            <Grid item xs={6}>
                                <Item className={classes.bsNone}>
                                    <FormControl className={classes.w100per}>
                                        <FormLabel id="period">Período</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="period-radio-buttons-group-label"
                                            name="period-radio-buttons-group"
                                            defaultValue="24H"
                                            onChange={(e, obj) => handlerDatapicker(e, obj)}
                                            value={dateType}
                                        >
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                                <Grid item xs={2} sm={6} md={6} lg={4} xl={3}>
                                                    <FormControlLabel className={classes.w100per} value="30M" control={<Radio />} label="30 Minutos" />
                                                    <FormControlLabel className={classes.w100per} value="1H" control={<Radio />} label="1 Hora" />
                                                    <FormControlLabel className={classes.w100per} value="3H" control={<Radio />} label="3 Horas" />
                                                    <FormControlLabel className={classes.w100per} value="12H" control={<Radio />} label="12 Horas" />
                                                    <FormControlLabel className={classes.w100per} value="24H" control={<Radio />} label="24 Horas" />
                                                </Grid>
                                                <Grid item xs={2} sm={6} md={6} lg={4} xl={3}>
                                                    <FormControlLabel className={classes.w100per} value="3D" control={<Radio />} label="3 Dias" />
                                                    <FormControlLabel className={classes.w100per} value="7D" control={<Radio />} label="7 Dias" />
                                                    <FormControlLabel className={classes.w100per} value="30D" control={<Radio />} label="30 Dias" />
                                                    <FormControlLabel className={classes.w100per} value="90D" control={<Radio />} label="90 Dias" />
                                                    <FormControlLabel className={classes.w100per} value="CUSTOM" control={<Radio />} label="Personalizado" />
                                                </Grid>
                                                <Grid item xs={4} sm={10} md={10} lg={8} xl={10}>
                                                    <Box width={1} className={classes.boxDatePicker}>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <DateTimePicker
                                                                className={`${hasErrorDataPicker ? classes.erroStyle : ''} ${classes.itemGrid2}`}
                                                                label="Data Inicial"
                                                                sx={{ display: isDateTimePickerVisible ? "inline-flex" : "none" }}
                                                                format="DD/MM/YYYY HH:ss"
                                                                value={valueIni}
                                                                onChange={handlerDatapickerIni}
                                                            />
                                                            <DateTimePicker
                                                                className={`${hasErrorDataPicker ? classes.erroStyle : ''} ${classes.itemGrid2}`}
                                                                label="Data Final"
                                                                sx={{ display: isDateTimePickerVisible ? "inline-flex" : "none" }}
                                                                format="DD/MM/YYYY HH:ss"
                                                                value={valueEnd}
                                                                onChange={handlerDatapickerEnd}
                                                            />
                                                        </LocalizationProvider>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </RadioGroup>
                                    </FormControl>
                                </Item>
                            </Grid>
                            <Grid item xs={6}>
                                <Item className={classes.bsNone}>
                                    <TextField
                                        label="Usuário"
                                        className={classes.itemGrid2}
                                        id="user"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder="Digite um Usuário"
                                        onChange={(e) => handlerUserNameChange(e, e.target.value)}
                                        value={userName}
                                    />
                                </Item>
                                <Item className={classes.bsNone}>
                                    <Autocomplete
                                        id="action"
                                        className={classes.itemGrid2}
                                        options={typeOptions}
                                        fullWidth
                                        onChange={(e, obj) => handlerActionChange(e, obj)}
                                        getOptionLabel={(option) => (Array.isArray(option) ? "" : option.label)}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        value={selectedType}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Tipo"
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                placeholder="Escolha uma opção..."
                                            />
                                        )}
                                    />
                                </Item>
                                <Item className={classes.bsNone}>
                                    <Autocomplete
                                        id="target"
                                        className={classes.itemGrid2}
                                        options={actionOptions}
                                        fullWidth
                                        onChange={(e, obj) => handlerTargetChange(e, obj)}
                                        getOptionLabel={(option) => (Array.isArray(option) ? "" : option.label)}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        value={selectedTarget}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Ação"
                                                InputLabelProps={{ shrink: true }}
                                                variant="outlined"
                                                placeholder="Escolha uma opção..."
                                            />
                                        )}
                                    />
                                </Item>
                                <Item className={classes.bsNone}>
                                    <TextField
                                        label="Descrição"
                                        className={classes.itemGrid2}
                                        id="user"
                                        fullWidth
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder="Digite uma Descrição"
                                        onChange={(e) => handlerDescriptionChange(e, e.target.value)}
                                        value={description}
                                    />
                                </Item>
                                <Item className={`${classes.bsNone} ${classes.mH4}`}>
                                    <Box>
                                        <Button className={classes.buttonSearch} onClick={handlerSearch}>
                                            <Typography className={classes.buttonText} variant="text">
                                                Buscar
                                            </Typography>
                                        </Button>
                                    </Box>
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </Collapse>
                <ToastContainer
                    pauseOnHover={false}
                    draggable={false}
                    autoClose={0}
                />
            </BoxContent>
        </>
    );
}


