import { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Menu, MenuItem, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function HeaderLinkMenu({ onClickHandler, id, routes }) {
  const [reportAnchorEl, setReportAnchorEl] = useState(null);
  const handleReports = (event) => {
    setReportAnchorEl(event.currentTarget);
  };
  const handleCloseReports = () => {
    setReportAnchorEl(null);
  };
  return (
    <>
      <Button
        sx={{ textTransform: "none" }}
        color="inherit"
        onClick={handleReports}
        endIcon={<ArrowDropDownIcon sx={{ color: "white" }} />}
      >
        <Typography
          variant="headerText"
          style={{ textDecoration: "none", color: "#fff" }}
        >
          Relatórios
        </Typography>
      </Button>
      <Menu
        id={id}
        anchorEl={reportAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(reportAnchorEl)}
        onClose={handleCloseReports}
      >
        {routes.map(({ name, path }, index) => (
          <Link
            key={index}
            to={path}
            style={{ textDecoration: "none", color: "#000" }}
            onClick={onClickHandler}
          >
            <MenuItem onClick={handleCloseReports}>{name}</MenuItem>
          </Link>
        ))}
      </Menu>
    </>
  );
}
