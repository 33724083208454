import { Stack, Typography } from "@mui/material";

export default function PageContainer({ title, children }) {
  return (
    <Stack width={1} direction="column" p="40px" gap={5}>
      <Typography variant="titleSection" fontWeight={600}>
        {title}
      </Typography>
      {children}
    </Stack>
  );
}
