import {
  Autocomplete,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { dominioEnum, statusEnum } from "../../utils/enumerations";
import BoxContent from "../BoxContent";
import BrlCurrencyComponent from "../CurrencyInput";
import PercentageField from "../PercentageInput";
import useEconomicSectorClient from "../../../src/clients/EconomicSectorClient";

function CustomerDetail({
  value,
  onChange = (value) => {},
  title,
  isMinimumRateRequired,
  minimumRateError,
  updatePost = (name, value) => {},
  economicSectorError,
  specialHookReset,
}) {
  const domainOptions = dominioEnum;
  const statusOptions = statusEnum;

  const economicSectorClient = useEconomicSectorClient();
  const [economicSectorOptions, setEconomicSectorOptions] = useState(null);

  const [details, setDetails] = useState(value);
  const [selectedDomain, setSelectedDomain] = useState(
    domainOptions.find((x) => x.value === value.domainId)
  );
  const [selectedStatus, setSelectedStatus] = useState(
    statusOptions.find((x) => x.value === value.statusId)
  );
  const [validityError, setValidityError] = useState(false);

  const [selectedEconomicSector, setSelectedEconomicSector] = useState("");

  const handleMinimumRateChange = (newValue) => {
    setDetails({
      ...details,
      minimumRate: newValue,
    });
    updatePost("minimumRate", newValue);
  };

  const handleMinimumPremiumChange = (newValue) => {
    setDetails({
      ...details,
      minimumPremium: newValue,
    });
    updatePost("minimumPremium", newValue);
  };

  const handleValidityChange = (validate, newValue) => {
    const hasError = validate.validationError !== null;
    setValidityError(hasError);
    if (!hasError) {
      const newValidity = dayjs(newValue.$d).format("YYYY-MM-DDTHH:mm:ss.SSSZ");
      setDetails({
        ...details,
        validity: newValidity,
      });
      updatePost("validity", newValidity);
    }
  };

  const handleSelectedDomainChange = (_, newDomain) => {
    setSelectedDomain(newDomain);
    const newDomainId = newDomain?.value ?? null;
    setDetails({
      ...details,
      domainId: newDomainId,
    });
    updatePost("domain", newDomainId);
  };

  const handleSelectedStatusChange = (_, newStatus) => {
    setSelectedStatus(newStatus);
    const newStatusId = newStatus?.value ?? null;
    setDetails({
      ...details,
      statusId: newStatusId,
    });
    updatePost("status", newStatusId);
  };

  const handleSelectedEconomicSectorChange = (_, newEconomicSector) => {
    setSelectedEconomicSector(newEconomicSector);
    const newEconomicSectorId = newEconomicSector?.value ?? null;
    setDetails({
      ...details,
      economicSectorId: newEconomicSectorId,
    });
    updatePost("economicSector", { id : newEconomicSectorId});
  };

  useEffect(() => {
    onChange(details);
  }, [onChange, details]);

  useEffect(() => {
    const client = economicSectorClient();
    client
      .getEconomicSector()
      .then((response) => {
        const data = response.data.Result;
        const options = [{ label: "", value: null }];
        
        data.forEach(element => {
          options.push({
            label : element.Name,
            value : String(element.Id).toLowerCase()
          });
        });
        
        setEconomicSectorOptions(options);
        setSelectedEconomicSector(options.find( (e) => e.value === value.economicSectorId));
        
      })
      .catch((error) => {
        console.error("Error fetching setor econômico: ", error);
      });
  }, [economicSectorClient]);
  
  useEffect(() => {
    setDetails(value);
    setSelectedDomain(domainOptions.find((x) => x.value === value.domainId));
    setSelectedStatus(statusOptions.find((x) => x.value === value.statusId));
    if(economicSectorOptions)
      setSelectedEconomicSector(economicSectorOptions.find( (e) => e.value === value.economicSectorId));
  }, [specialHookReset,]);

  const inputStyle = {
    borderRadius: "4px",
    color: "rgba(19, 18, 18, 0.6)",
    fontSize: "14px",
    fontFamily: "Roboto",
    fontWeight: 400,
  };

  return (
    <BoxContent title={title}>
      <Grid
        container
        columns={{ xs: 4, sm: 8, md: 12 }}
        columnSpacing={4}
        rowSpacing={3}
        pt={5}
      >
        <Grid item xs={4}>
          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="pt-br"
          >
            <DatePicker
              sx={{
                width: 1,
                fontSize: 13,
                Input: inputStyle,
                label: { fontSize: 13, top: 3 },
              }}
              label="Data de Validade"
              format="DD/MM/YYYY"
              minDate={dayjs().startOf("years").year(2000)}
              slotProps={{
                textField: {
                  helperText: validityError ? "Data inválida." : "",
                },
                day: {
                  color: "blue"
                },
              }}
              value={details.validity ? dayjs(details.validity) : null}
              onChange={(value, validate) => {
                handleValidityChange(validate, value);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={4}>
          <PercentageField
            fullWidth
            value={details.minimumRate}
            onChange={(_, value) => {
              handleMinimumRateChange(value);
            }}
            maximumFractionDigits={2}
            label="Taxa Mínima"
            helperText={minimumRateError ? "Percentual inválido." : null}
            required={isMinimumRateRequired}
            error={minimumRateError}
            InputLabelProps={{
              style: {
                fontSize: 13,
                top: 3,
              },
            }}
            InputProps={{
              style: inputStyle,
              inputProps: {
                maxLength: 6,
              },
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <BrlCurrencyComponent
            fullWidth
            value={details.minimumPremium}
            onChange={(_, value) => {
              handleMinimumPremiumChange(value);
            }}
            label="Prêmio Mínimo"
            InputLabelProps={{
              style: {
                fontSize: 13,
                top: 3,
              },
            }}
            InputProps={{
              style: inputStyle,
              inputProps: {
                maxLength: 21,
              },
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <FormControl
            fullWidth
            sx={{
              fontSize: 13,
              Input: inputStyle,
              label: { fontSize: 13, top: 3 },
            }}
          >
            <Autocomplete
              id="dominioId"
              options={domainOptions}
              value={selectedDomain || null}
              onChange={handleSelectedDomainChange}
              getOptionLabel={(option) =>
                Array.isArray(option) ? "" : option.label
              }
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Dominio"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  placeholder="Escolha uma opção..."
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            fullWidth
            sx={{
              fontSize: 13,
              Input: inputStyle,
              label: { fontSize: 13, top: 3 },
            }}
          >
            <Autocomplete
              id="statusTomadorId"
              options={statusOptions}
              value={selectedStatus || null}
              onChange={handleSelectedStatusChange}
              getOptionLabel={(option) =>
                Array.isArray(option) ? "" : option.label
              }
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status do Tomador"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  placeholder="Escolha uma opção..."
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl
            fullWidth
            sx={{
              fontSize: 13,
              Input: inputStyle,
              label: { fontSize: 13, top: 3 },
            }}
          >
            <Autocomplete
              id="setorEconomicoId"
              options={economicSectorOptions}
              value={selectedEconomicSector || null}
              onChange={handleSelectedEconomicSectorChange}
              getOptionLabel={(option) =>
                Array.isArray(option) ? "" : option.label
              }
              isOptionEqualToValue={(option, value) =>
                option.value === value.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Setor Econômico"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  placeholder="Escolha uma opção..."
                  error={economicSectorError}
                  helperText={economicSectorError ? "Campo Obrigatório" : null}
                />
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    </BoxContent>
  );
}

CustomerDetail.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.shape({
    minimumRate: PropTypes.number,
    minimumPremium: PropTypes.number,
    validity: PropTypes.string,
    domainId: PropTypes.number,
    statusId: PropTypes.number,
    economicSectorId: PropTypes.string,
  }),
  onChange: PropTypes.func,
  isMinimumRateRequired: PropTypes.bool,
  error: PropTypes.object,
  updatePost: PropTypes.func,
};

export default CustomerDetail;
