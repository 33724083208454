import { useCallback } from "react";
import http from "../api/http";

const useLogsClient = () => {
  const getLogs = useCallback(async (filters) => {
    return http.get(`/user-actions/logs`, {
      params: filters,
    });
  }, []);

  return useCallback(
    () => ({
      getLogs,
    }),
    [getLogs]
  );
};

export default useLogsClient;
