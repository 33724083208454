import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    backgroundColor: "#ffffff",
    borderRadius: 1,

    "@media (max-width: 425px)": {
      flexDirection: "column",
      gap: "10px",
    },
  },

  gridModality: {
    gap: "30px",

    "@media (max-width: 425px)": {
      justifyContent: "flex-start",
      maxWidth: "100% !important",
    },
  },

  gridLimitIS: {
    gap: "20px",

    "@media (max-width: 425px)": {
      justifyContent: "flex-start",
      maxWidth: "100% !important",
    },
  },

  gridMaxValidity: {
    gap: "20px",

    "@media (max-width: 425px)": {
      justifyContent: "flex-start",
      maxWidth: "100% !important",
    },
  },

  chip: {
    minWidth: 100,

    "& .MuiChip-label": {
      fontFamily: "Roboto",
      fontWeight: 700,
      fontSize: "14px",
      color: "#142A70",
      padding: "0 16px",
    },

    "&.MuiChip-root": {
      backgroundColor: theme.palette.primary.lightGrayishBlue,
    },
  },

  button: {
    "&.MuiButton-root": {
      padding: "13px 45px",
      backgroundColor: theme.palette.primary.steelBlue,
      borderRadius: "8px",

      "&:hover": {
        backgroundColor: theme.palette.primary.steelBlue,
        opacity: 0.8,
      },

      "&:disabled": {
        backgroundColor: theme.palette.secondary.lightGray,
      }
    },
  },

  textButton: {
    color: theme.palette.secondary.light,
    textTransform: "capitalize",
  },

  textField: {
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.lightSeaGreen,
    },

    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.lightSeaGreen,
    },
  },
}));

export default useStyles;