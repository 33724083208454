import { Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import PageContainer from "../../components/PageContainer";
import SearchPrincipal from "./SearchPrincipal";

export default function PrincipalsPage() {
  return (
    <PageContainer title="Tomador">
      <Stack width={1} gap={3}>
        <SearchPrincipal />
        <Outlet />
      </Stack>
    </PageContainer>
  );
}
