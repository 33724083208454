import ErrorIcon from "@mui/icons-material/Error";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useState } from "react";

export default function DownloadButton({
  title = "Clique aqui para baixar o arquivo",
  onDownload = async () => {},
  resultIconTimeout = 3000,
}) {
  const [downloading, setDownloading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleDownload = async () => {
    setDownloading(true);
    setSuccess(false);
    setError(false);
    try {
      await onDownload();
      setDownloading(false);
      handleDownloadSuccess();
    } catch (error) {
      setError();
    } finally {
      setDownloading(false);
      handleDownloadError();
    }
  };

  const handleDownloadSuccess = () => {
    setSuccess(true);
    setTimeout(() => setSuccess(false), resultIconTimeout);
  };

  const handleDownloadError = () => {
    setError(true);
    setTimeout(() => setError(false), resultIconTimeout);
  };

  return (
    <Box sx={{ m: 1, position: "relative" }}>
      <Tooltip title={title} placement="left">
        <IconButton
          color="primary"
          sx={{ border: 1 }}
          onClick={handleDownload}
          disabled={downloading || success}
        >
          {success ? (
            <FileDownloadDoneIcon />
          ) : error ? (
            <ErrorIcon />
          ) : (
            <FileDownloadIcon />
          )}
        </IconButton>
      </Tooltip>
      {downloading && (
        <CircularProgress
          size={50}
          sx={{
            color: "primary",
            position: "absolute",
            top: -4,
            left: -4,
            zIndex: 1,
          }}
        />
      )}
    </Box>
  );
}
