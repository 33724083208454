import { useCallback } from "react";
import http from "../api/http";

const useGrupoEconomicoClient = () => {
  const searchEconomicGroup = useCallback(
    async (/** @type {string} */ name) => {
      return http.get("/economic-groups", {
        params: { name },
      });
    },
    []
  );

  /**
   * @type {(id:string) => Promise<import("axios").AxiosResponse<any, any>>}
   */
  const getGrupoEconomico = useCallback(async (id) => {
    return http.get(`/economic-groups/${id}`);
  }, []);

  const saveGrupoEconomico = useCallback(async (body, id) => {
    return http.put(`/economic-groups/${id}`, body);
  }, []);

  const getGrupoEconomicoRatings = useCallback(async () => {
    return http.get(`/economic-groups/ratings`);
  }, []);

  const getGrupoEconomicoRatingsDownload = useCallback(async () => {
    return http.get(`/economic-groups/ratings/download`);
  }, []);

  return useCallback(
    () => ({
      searchEconomicGroup,
      getGrupoEconomico,
      saveGrupoEconomico,
      getGrupoEconomicoRatings,
      getGrupoEconomicoRatingsDownload,
    }),
    [
      searchEconomicGroup,
      getGrupoEconomico,
      saveGrupoEconomico,
      getGrupoEconomicoRatings,
      getGrupoEconomicoRatingsDownload,
    ]
  );
};

export default useGrupoEconomicoClient;
