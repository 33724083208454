import { makeStyles } from "@mui/styles";
import { Box, Button, Modal, Typography } from "@mui/material";
import CloseButton from "../../assets/img/close-button.svg";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minWidth: 448,
    borderRadius: 8,
  },

  button: {
    width: "100%",

    "&.MuiButton-root": {
      padding: "13px 45px",
      backgroundColor: theme.palette.primary.steelBlue,
      borderRadius: "8px",

      "&:hover": {
        backgroundColor: theme.palette.primary.steelBlue,
        opacity: 0.8,
      },
    },
  },

  textButton: {
    color: theme.palette.secondary.light,
    textTransform: "capitalize",
  },

  cancelButton: {
    width: "100%",

    "&.MuiButton-root": {
      padding: "13px 45px",
      backgroundColor: theme.palette.secondary.light,
      borderRadius: "8px",
      border: `1.5px solid ${theme.palette.primary.main}`
    },
  },

  textCancelButton: {
    color: theme.palette.primary.dark,
    textTransform: "capitalize",
  },

  textModal: {
    textAlign: "center",
  },

  boxWrapperImg: {
    background: '#25AF36',
    borderRadius: "50%",
    height: 70,
    width: 70,
  },

  closeButton: {
    cursor: "pointer",
  },
}));

const MyModal = (props) => {
  const classes = useStyles();

  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.onClose}
        className={classes.modal}
      >
        <div className={classes.paper}>
          <Box display="flex" justifyContent="flex-end" width={1}>
            <Box
              component="img"
              src={CloseButton}
              alt="Ícone de fechar"
              onClick={props.onClose}
              className={classes.closeButton}
            />
          </Box>

          {props.editIcon ? (
            <Box component="img" src={props.icon} alt="Ícone de ok" />
          ): (
            <Box
              display="flex"
              justifyContent="center"
              className={classes.boxWrapperImg}
            >
              <Box component="img" src={props.icon} alt="Ícone de editar" />
            </Box>
          )}

          <Box
            display="flex"
            justifyContent="center"
            width="200px"
            pt="10px"
            pb="30px"
          >
            <Typography variant="textModal" className={classes.textModal}>
              {props.title}
            </Typography>
          </Box>

          {props.description ? (
            <Box
              display="flex"
              justifyContent="center"
              width="460px"
              pt="10px"
              pb="30px"
            >
              <Typography
                variant="text2"
                className={classes.textModal}
                fontWeight={400}
              >
                {props.description}
              </Typography>
            </Box>
          ) : null}

          <Box display="flex" width={1} gap="16px">
            {props.hasCancelButton ? (
              <Button
                onClick={props.onClose}
                className={classes.cancelButton}
              >
                <Typography variant="text2" className={classes.textCancelButton}>
                  Cancelar
                </Typography>
              </Button>
            ) : null}

            <Button
              onClick={props.onConfirm}
              className={classes.button}
            >
              <Typography variant="text2" className={classes.textButton}>
                Confirmar
              </Typography>
            </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

export default MyModal;
