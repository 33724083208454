import { useEffect, useState } from "react";
import { Grid, Skeleton, Stack, Typography } from "@mui/material";
import { debounce } from "lodash";
import useGrupoEconomicoClient from "../../../clients/GrupoEconomicoClient";
import DownloadButton from "../../../components/DownloadButton";
import ReportFilter from "./ReportFilter";
import ReportTable from "./ReportTable";
import ReportPagination from "../../../components/ReportPagination";

export default function RatingsReport() {
  const grupoEconomicoClient = useGrupoEconomicoClient();
  const [loading, setLoading] = useState(true);
  const [rawData, setRawData] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const handleDownload = async () => {
    const client = grupoEconomicoClient();
    const response = await client.getGrupoEconomicoRatingsDownload();
    const url = `data:text/csv;base64,${response.data.Result[0].File}`;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      "Guardian - Relatório de Ratings por Grupo Econômico.xlsx"
    );
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (rows) => {
    setRowsPerPage(+rows);
  };

  const termFilter = (term, item) =>
    !term || item.Name.toLowerCase()?.includes(term?.toLowerCase());

  const handleFilterChange = debounce((searchTerm) => {
    setSearchTerm(searchTerm);
    setPage(0);
    setCount(rawData.filter((item) => termFilter(searchTerm, item)).length);
    setRowsPerPage(rowsPerPage);
  }, 200);

  useEffect(() => {
    const fetchRating = async () => {
      try {
        const client = grupoEconomicoClient();
        const response = await client.getGrupoEconomicoRatings();
        setRawData(response.data.Result);
        setCount(response.data.Result.length);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchRating();
  }, [grupoEconomicoClient]);

  return (
    <Grid container display="flex" flexDirection="column" p="40px">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography variant="titleSection" fontWeight={600}>
          Relatórios - Ratings por Grupo Econômico
        </Typography>
        <DownloadButton
          title="Clique aqui para baixar o relatório"
          onDownload={handleDownload}
        />
      </Grid>
      {loading ? (
        <Stack gap={4} marginTop={4}>
          <Skeleton
            width="100%"
            height="180px"
            animation="wave"
            variant="rectangular"
          />
          <Skeleton
            width="100%"
            height="580px"
            animation="wave"
            variant="rectangular"
          />
        </Stack>
      ) : (
        <>
          <ReportFilter title="Filtrar Ratings" onSearch={handleFilterChange} />
          <ReportPagination
            count={count}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <ReportTable
            data={rawData
              .filter((item) => termFilter(searchTerm, item))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
          />
        </>
      )}
    </Grid>
  );
}
