import { Stack } from "@mui/material";
import React, { useCallback, useEffect, useState, useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckIcon from "../../assets/img/check-icon.svg";
import EditIcon from "../../assets/img/edit-icon.svg";
import useGrupoEconomicoClient from "../../clients/GrupoEconomicoClient";
import BoxContent from "../../components/BoxContent";
import CreditLimit from "../../components/CreditLimit";
import CustomerDetail from "../../components/CustomerDetail";
import CustomersList from "../../components/CustomersList";
import LoadingButton from "../../components/LoadingButton";
import MyModal from "../../components/Modal";
import ModalityGroupLimits from "../../components/ModalityGroupLimits";
import PageContainer from "../../components/PageContainer";
import SpecialAcceptance from "../../components/SpecialAcceptance";
import Rating from "../Ratings/Rating";
import SearchEconomicGroup from "./SearchEconomicGroup";
import { v4 as uuidv4 } from 'uuid';
import { AppContext } from "../../context/context";

const defaultPayload = {
  limit: null,
  minimumPremium: null,
  portalRatePercent: null,
  minimumRate: null,
  specialAcceptance: null,
  specialAcceptanceRate: null,
  validity: null,
  domain: null,
  status: null,
  rating: null,
  modalityLimits: [],
  economicSector: null
};

const EconomicGroup = () => {
  const context = useContext(AppContext);
  const [economicGroupId, setEconomicGroupId] = useState(0);
  const [controlRequest, setControlRequest] = useState(0);
  const grupoEconomicoClient = useGrupoEconomicoClient();
  const [economicGroup, setEconomicGroup] = useState(null);
  const [payloadPost, setPayloadPost] = useState({ ...defaultPayload });
  const [hasEconomicSector, setHasEconomicSector] = useState(false);

  const [globalLimit, setGlobalLimit] = useState(0);
  const [minimumRate, setMinimumRate] = useState(0);

  const [modalConfirm, setModalConfirm] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);

  const [acceptanceRateError, setAcceptanceRateError] = useState(false);
  const [portalPercentageError, setPortalPercentageError] = useState(false);
  const [minimumRateError, setMinimumRateError] = useState(false);
  const [economicSectorError, setEconomicSectorError] = useState(false);
  
  const [blocked, setBlocked] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showToastError, setShowToastError] = useState(false);
  const [isSending, setIsSending] = useState(false);
  let errorsx = errors;

  useEffect(() => {
    setEconomicGroup(null);
    setEconomicGroupId(0);
    setGlobalLimit(0);
    setMinimumRate(0);
  }, [setEconomicGroupId, setEconomicGroup]);

  const resetPayload = useCallback(() => {
    setPayloadPost({ ...defaultPayload });
    setControlRequest(uuidv4());
  }, [setPayloadPost]);

  useEffect(() => {
    if (economicGroupId) {

      /*if(context.isChange){
        if(!window.confirm("Ë possível que haja alterações não salvas, deseja sair?"))
          return false;
      }*/

      const toastId = toast.info("Realizando requisição...", {
        autoClose: false,
        onClose: () => { },
      });

      const client = grupoEconomicoClient();
      client
        .getGrupoEconomico(economicGroupId)
        .then(({ data }) => {
          setEconomicGroup({
            ...data.Result[0],
            Limits: data.Result[0].Limits ?? {
              Limit: null,
              Exposure: null,
              Validity: null,
              MinimumPremium: null,
              PortalRatePercent: null,
              MinimumRate: null,
              SpecialAcceptance: false,
              Available: null,
            },
          });
          context.setIsChange(false);  
          setGlobalLimit(data.Result[0].Limits?.Limit ?? 0);
          setMinimumRate(data.Result[0].Limits?.MinimumRate ?? 0);
          setHasEconomicSector(data.Result[0].EconomicSector?.Id ? true : false);
          resetPayload();
          toast.update(toastId, {
            render: "Requisição feita com sucesso!",
            type: toast.TYPE.SUCCESS,
            autoClose: 5000,
            pauseOnFocusLoss: false,
          });
        })
        .catch((error) => {
          console.error("Error fetching other data:", error);
          const descriptions = error.response.data.Messages.map(
            (message) => message.Description
          );
          var errorMessage = descriptions.join("\n");
          toast.update(toastId, {
            render: errorMessage,
            type: toast.TYPE.ERROR,
            autoClose: 5000,
            pauseOnFocusLoss: false,
          });
        });
    }
  }, [economicGroupId, setEconomicGroup, grupoEconomicoClient, resetPayload]);

  const updatePost = useCallback(
    (key, obj) => {
      const payload = payloadPost;
      payload[key] = obj;
      setPayloadPost(payload);
      context.setIsChange(true);
    },
    [payloadPost]
  );

  const savePost = useCallback(() => {
    const toastId = toast.info("Enviando dados...");
    setIsSending(true);
    grupoEconomicoClient()
      .saveGrupoEconomico(payloadPost, economicGroupId)
      .then(() => {
        toast.dismiss(toastId);
        setIsSending(false);
        setModalSuccess(true);
        context.setIsChange(false);

        /*É necessário zerar o payload, pois se o usuário alterar algo novamente na mesma tela sem ter feito um refresh 
        a informação da requisição anterior ainda estará disponível sendo enviada para o backend e gerando inconsistência no log de ações*/
        resetPayload();
      })
      .catch((error) => {
        setIsSending(false);
        let errorMessage =
          "Falha ao enviar os dados. Por favor, tente novamente.";
        const descriptions = error.response.data.Messages.map(
          (message) => message.Description
        );
        errorMessage = descriptions.join("\n");
        toast.update(toastId, {
          render: errorMessage,
          type: toast.TYPE.ERROR,
          autoClose: 5000,
          pauseOnFocusLoss: false,
        });
      });
  }, [grupoEconomicoClient, economicGroupId, payloadPost, resetPayload]);

  const handleSave = () => {
    if (blocked) {
      setShowToastError(true);
      return;
    }

    setModalConfirm(true);
  };

  const handleToastClose = () => {
    setShowToastError(false);
  };

  useEffect(() => {
    if (showToastError) {
      toast.error("Favor revisar o formulário.", {
        onClose: handleToastClose,
        autoClose: 5000,
        pauseOnFocusLoss: false,
      });
    }
  }, [showToastError]);

  const handleSpecialAcceptanceRateChange = useCallback(
    (value) => {
      updatePost("specialAcceptance", value.enabled);
      updatePost("specialAcceptanceRate", value.enabled ? value.rate : null);
      const validRate = !value.enabled || (value.rate > 0 && value.rate <= 100);
      setAcceptanceRateError(!validRate);
      setBlocked(!validRate);
    },
    [updatePost]
  );

  const handleLimitPortalPercentageChange = (newPortalPercentage) => {
    updatePost("portalRatePercent", newPortalPercentage);
    if (newPortalPercentage > 0 && newPortalPercentage <= 100) {
      setPortalPercentageError(false);
      setBlocked(false);
      return;
    }

    setPortalPercentageError(true);
    setBlocked(true);
  };

  const handleValidation = (value, modality, type) => {
    const newErrors = [...errorsx];
    switch (type) {
      case "minimumPremium":
        const validMinimumPremium =
          value >= economicGroup?.Limits.MinimumPremium;

        newErrors[modality.GroupModalityId] = {
          ...newErrors[modality.GroupModalityId],
          minimumPremium: !validMinimumPremium,
        };
        break;

      case "minimumRate":
        const validMinimumRate =
          value > 0 && value <= 100.0 && value >= minimumRate;
        newErrors[modality.GroupModalityId] = {
          ...newErrors[modality.GroupModalityId],
          minimumRate: !validMinimumRate,
        };
        break;

      case "limitPortal":
        const validLimitPortal = value > 0 && value <= globalLimit;
        newErrors[modality.GroupModalityId] = {
          ...newErrors[modality.GroupModalityId],
          limitPortal: !validLimitPortal,
        };
        break;

      case "limitIS":
        const validLimitIS = value > 0;
        newErrors[modality.GroupModalityId] = {
          ...newErrors[modality.GroupModalityId],
          limitIs: !validLimitIS,
        };
        break;

      case "maximumDuration":
        const validMaximumDuration = value > 0;
        newErrors[modality.GroupModalityId] = {
          ...newErrors[modality.GroupModalityId],
          maximumDuration: !validMaximumDuration,
        };
        break;

      case "limitERP":
        {
          const creditLimitGlobal = economicGroup?.Limits.Limit;
          const condition = value > 0 && value <= creditLimitGlobal;
          newErrors[modality.GroupModalityId] = {
            ...newErrors[modality.GroupModalityId],
            limitErp: !condition,
          };
        }
        break;

      case "activePortal":
        newErrors[modality.GroupModalityId] = {
          ...newErrors[modality.GroupModalityId],
          limitPortal: false,
          limitIs: false,
          maximumDuration: false,
        };
        break;

      default:
        break;
    }
    setBlocked(
      newErrors.some((e) => {
        return (
          e?.maximumDuration ||
          e?.limitIs ||
          e?.limitPortal ||
          e?.limitErp ||
          e?.minimumRate ||
          e?.minimumPremium
        );
      })
    );

    errorsx = [...newErrors];
    setErrors(errorsx);
  };

  const getErrorProps = (id, type) => {
    let error = false;
    let required = false;

    switch (type) {
      case "minimumPremium":
        error = errors[id]?.minimumPremium;
        required = errors[id]?.minimumPremium;
        break;

      case "minimumRate":
        error = errors[id]?.minimumRate;
        required = errors[id]?.minimumRate;
        break;

      case "limitErp":
        error = errors[id]?.limitErp;
        required = errors[id]?.limitErp;
        break;

      case "limitPortal":
        error = errors[id]?.limitPortal;
        required = errors[id]?.limitPortal;
        break;

      case "limitIs":
        error = errors[id]?.limitIs;
        required = errors[id]?.limitIs;
        break;

      case "maximumDuration":
        error = errors[id]?.maximumDuration;
        required = errors[id]?.maximumDuration;
        break;

      default:
        break;
    }

    return {
      error,
      required,
    };
  };

  const handleDetailChanges = useCallback((value) => {
    const hasError = value.minimumRate <= 0 || value.minimumRate > 100;
    setMinimumRate(value.minimumRate);
    setMinimumRateError(hasError);
    setBlocked(hasError);

    if(hasEconomicSector){
      const hasError = value.economicSectorId == null || value.economicSectorId == "";
      setBlocked(hasError);
      setEconomicSectorError(hasError);
    }
    else{
      setEconomicSectorError(false);
    }

  }, [hasEconomicSector]);

  const handleRatingChanges = useCallback(
    (value) => {
      updatePost("rating", value);
      setEconomicGroup({
        ...economicGroup,
        Rating: value,
      });
    },
    [updatePost, economicGroup]
  );

  return (
    <>
      <PageContainer title="Grupo Econômico">
        <SearchEconomicGroup
          economicGroup={economicGroup}
          onGroupSelected={(group) => {
            setErrors([]);
            resetPayload();
            setEconomicGroup(null);
            setEconomicGroupId(Number(group?.id ?? 0));
          }}
        />

        {economicGroupId !== 0 && economicGroup && (
          <>
            {economicGroup && (
              <Rating
                rating={economicGroup?.Rating}
                onChange={handleRatingChanges}
              />
            )}

            <CreditLimit
              limits={economicGroup?.Limits}
              isPortalPercentRequired={true}
              hasPortalPercentError={portalPercentageError}
              onChangeGlobalLimit={(newLimit) => {
                setGlobalLimit(newLimit);
                updatePost("limit", newLimit);
              }}
              onChangePortalPercentage={handleLimitPortalPercentageChange}
            />

            <CustomerDetail
              title="Detalhe do Grupo Econômico"
              value={{
                minimumPremium: economicGroup.Limits?.MinimumPremium,
                minimumRate: economicGroup?.Limits?.MinimumRate,
                validity: economicGroup?.Limits?.Validity,
                domainId: economicGroup?.Domain,
                statusId: economicGroup?.StatusI4Pro,
                economicSectorId: economicGroup?.EconomicSector?.Id,
              }}
              onChange={handleDetailChanges}
              updatePost={updatePost}
              isMinimumRateRequired={true}
              minimumRateError={minimumRateError}
              economicSectorError={economicSectorError}
            />

            <SpecialAcceptance
              enabled={economicGroup?.Limits?.SpecialAcceptance}
              rate={economicGroup?.Limits?.SpecialAcceptanceRate}
              hasAcceptanceRateError={acceptanceRateError}
              onChange={handleSpecialAcceptanceRateChange}
            />

            <ModalityGroupLimits
              modalityLimits={economicGroup?.Limits?.ModalityLimits ?? []}
              minimumPremiumGE={economicGroup?.Limits?.MinimumPremium}
              handleValidation={handleValidation}
              getErrorProps={getErrorProps}
              updatePost={updatePost}
              economicGroupId={economicGroupId}
              controlRequest={controlRequest}
            />

            <Stack direction="row-reverse" width={1}>
              <LoadingButton loading={isSending} onClick={() => handleSave()}>
                Salvar
              </LoadingButton>
            </Stack>

            <BoxContent title="Tomadores">
              <CustomersList
                customers={economicGroup?.Principals?.map((p) => ({
                  ...p,
                  Link: `/principals/${p.Id}`,
                }))}
                emptyListText="Nenhum tomador associado a este grupo."
              />
            </BoxContent>
          </>
        )}
      </PageContainer>
      <MyModal
        title="Alteração Grupos"
        description="A alteração dos dados de Grupo Econômico serão replicados para todos os Tomadores. Deseja prosseguir com a alteração?"
        hasCancelButton={true}
        editIcon={true}
        icon={EditIcon}
        open={modalConfirm}
        onClose={() => setModalConfirm(false)}
        onConfirm={() => {
          setModalConfirm(false);
          savePost();
        }}
      />

      <MyModal
        title="Dados salvos com sucesso!"
        icon={CheckIcon}
        open={modalSuccess}
        onClose={() => setModalSuccess(false)}
        onConfirm={() => setModalSuccess(false)}
        closeButton={() => setModalSuccess(false)}
      />

      <ToastContainer pauseOnHover={false} draggable={false} autoClose={0} />
    </>
  );
};

export default EconomicGroup;
