import { Box, Grid, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import BoxContent from "../../../components/BoxContent";

export default function ReportFilter({ title = "", onSearch = (term) => {} }) {
  return (
    <>
      <Grid width={1} container flexDirection="row" mt="20px">
        <BoxContent title={title}>
          <Box
            width={1}
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              paddingTop: "40px",
            }}
          >
            <TextField
              type="text"
              fullWidth
              variant="outlined"
              placeholder="Digite o nome do grupo para filtrar o relatório"
              label={
                <>
                  <SearchIcon style={{ marginLeft: 15, fontSize: "small" }} />
                  <span> Grupo Econômico</span>
                </>
              }
              onChange={(e) => onSearch(e.target.value)}
            />
          </Box>
        </BoxContent>
      </Grid>
    </>
  );
}
