import { Paper } from "@mui/material";
import { formatCurrency, formatDocument } from "../../utils/formatFunction";
import CustomerSummaryDetails from "./CustomerSummaryDetails";
import CustomerSummaryHeader from "./CustomerSummaryHeader";

export default function CustomerSummary({ customer }) {
  customer = {
    ...customer,
    DocumentNumber:
      customer.DocumentNumber === "0"
        ? null
        : formatDocument(customer.DocumentNumber),
    LastUpdateI4Pro:
      customer.LastUpdateI4Pro && customer.LastUpdateI4Pro.split("-")[0] > 1
        ? new Date(customer.LastUpdateI4Pro).toLocaleDateString("pt-BR", {
            timeZone: "UTC",
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })
        : "-",
    Limits: {
      ...customer?.Limits,
      Limit: customer.Limits?.Limit ? formatCurrency(customer.Limits.Limit) : 0,
      Exposure: customer.Limits?.Limit
        ? formatCurrency(customer.Limits.Exposure)
        : 0,
      Available: customer.Limits?.Limit
        ? formatCurrency(customer.Limits.Available)
        : 0,
      Validity:
        customer.Limits?.Validity?.split("-")[0] > 1
          ? new Date(customer.Limits.Validity).toLocaleDateString("pt-BR", {
              timeZone: "UTC",
            })
          : "-",
      MinimumRate: customer.Limits?.MinimumRate
        ? customer.Limits.MinimumRate
        : 0,
      MinimumPremium: customer.Limits?.MinimumPremium
        ? formatCurrency(customer.Limits.MinimumPremium)
        : "-",
    },
  };
  return (
    <Paper variant="outlined" sx={{ backgroundColor: "#F4F4F4" }}>
      <CustomerSummaryHeader customer={customer} />
      <CustomerSummaryDetails customer={customer} />
    </Paper>
  );
}
