import { useState } from "react";
import {
  Typography,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Box,
  Tooltip
} from "@mui/material";
import { grey } from "@mui/material/colors";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ReportTableCollapse from "./ReportTableCollapse";
import useStyles from "./styles.js";
import { v4 as uuidv4 } from 'uuid';


import "react-toastify/dist/ReactToastify.css";


export default function ReportTable({ data, ratings, getEconomicSectorName }) {
  const classes = useStyles();
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="table">
          <TableHead>
            <TableRow sx={{ backgroundColor: grey[100] }}>
              <TableCell />
              <TableCell className={`${classes.pdHeaderTable} ${classes.mW20}`}>
                <Typography variant="span">Usuário</Typography>
              </TableCell>
              <TableCell className={`${classes.pdHeaderTable} ${classes.mW20}`}>
                <Typography variant="span">Tipo</Typography>
              </TableCell>
              <TableCell className={`${classes.pdHeaderTable} ${classes.mW20}`}>
                <Typography variant="span">Ação</Typography>
              </TableCell>
              <TableCell className={`${classes.pdHeaderTable} ${classes.mW20}`}>
                <Typography variant="span">Data/hora</Typography>
              </TableCell>
              <TableCell className={`${classes.pdHeaderTable} ${classes.mW20}`}>
                <Typography variant="span">ID da Ação</Typography>
              </TableCell>
              <TableCell className={`${classes.pdHeaderTable} ${classes.mW20}`}>
                <Typography variant="span">Descrição</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length !== 0 ? data.map((row) => (
              <Row key={uuidv4()} row={row} ratings={ratings} getEconomicSectorName={getEconomicSectorName}/>
            )) : (null)}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length === 0 ? (
        <Box paddingTop="1.5em">
          <Typography variant="h4" gutterBottom className={classes.noresult}>
            Sem Resultado.
          </Typography>
        </Box>
      ) : (null)}
    </>
  );
}

function Row({ row, ratings, getEconomicSectorName }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const targetMapping = {
    EconomicGroup : "Grupo Econômico",
    Modality : "Modalidade",
    Principal : "Tomador",
  }

  const typeMapping = {
    Insert : "Inclusão",
    Update : "Atualização",
    Delete : "Exclusão",
  }


  return (
    <>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component="th" scope="row" className={classes.pdNone}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            disabled={!row.FieldChanges?.length}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.pdNone}>
          <Tooltip title={row.User.Name} className={classes.cursorpointer}>
            <Typography variant="description">
              {row.User.User}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell className={classes.pdNone}>
          <Typography variant="description">
            {typeMapping[row.Type] ? typeMapping[row.Type] : row.Type}
          </Typography>
        </TableCell>
        <TableCell className={classes.pdNone}>
          <Typography variant="description">
            {targetMapping[row.Target] ? targetMapping[row.Target] : row.Target}
          </Typography>
        </TableCell>
        <TableCell className={classes.pdNone}>
          <Typography variant="description">
            {row.ActionDate && row.ActionDate.split("-")[0] > 1 ? (
              new Date(new Date(row.ActionDate).getTime() - 3 * 60 * 60 * 1000)
                .toLocaleString("pt-BR", {
                  timeZone: "UTC",
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })
            ) : (
              "-"
            )}
          </Typography>
        </TableCell>
        <TableCell className={classes.pdNone}>
          <Typography variant="description">
            {row.Context.ReferenceId}
          </Typography>
        </TableCell>
        <TableCell className={classes.pdNone}>
          <Typography variant="description">
            {row.Context.Description}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          {row.FieldChanges != null && row.FieldChanges.length > 0 ? (
            <Collapse in={open} timeout="auto" unmountOnExit>
              <ReportTableCollapse data={row.FieldChanges} ratings={ratings} getEconomicSectorName={getEconomicSectorName}/>
            </Collapse>
          ) : (
            ""
          )}
        </TableCell>
      </TableRow>

    </>
  );
}
