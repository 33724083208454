import { Grid, Skeleton, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useModalityClient from "../../clients/ModalityClient";
import BoxContent from "../BoxContent";
import ModalityGroupRow from "./ModalityGroupRow";

function HeaderCell({ weight, children }) {
  const theme = useTheme();
  return (
    <Grid
      item
      xs={weight}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography
        variant="poppins15"
        color={theme.palette.secondary.light}
        textAlign="center"
        alignSelf="center"
      >
        {children}
      </Typography>
    </Grid>
  );
}

const ModalityGroupLimits = ({
  modalityLimits,
  minimumPremiumGE,
  economicGroupId,
  controlRequest,
  updatePost = (name, value) => {},
  handleValidation = (value, modality, type) => {},
  getErrorProps = (id, type) => {},
}) => {
  const modalityClient = useModalityClient();
  const [loading, setLoading] = useState(true);
  const [modalities, setModalities] = useState([]);
  const [updateModalidades, setUpdateModalidades] = useState([]);
  const [modalidadesExibicao, setModalidadesExibicao] = useState();

  useEffect(() => {
    const fetchModalities = async () => {
      try {
        const client = modalityClient();
        const response = await client.getModalityGroups();
        const data = response.data.Result.map((item) => {
          return {
            Id: item.Id,
            Description: item.Description,
          };
        });
        data?.sort((a, b) =>
          a.Description.localeCompare(b.Description, "en", {
            sensitivity: "base",
          })
        );

        handlerCompleteArrayModality(data);
        setModalidadesExibicao(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching modalities:", error);

        const descriptions = error.response.data.Messages.map(
          (message) => message.Description
        );
        var errorMessage = descriptions.join("\n");

        toast.error(errorMessage, {
          autoClose: 5000,
        });
        setLoading(false);
      }
    };

    fetchModalities();
  }, [modalityClient, setModalidadesExibicao, setLoading]);

  useEffect(() => {
    setModalities(modalityLimits);
  }, [modalityLimits]);

  /*Ë necessário zerar o payload, pois se o usuário alterar algo novamente na mesma tela sem ter feito um refresh 
  a informação da requisição anterior ainda estará disponível sendo enviada para o backend e gerando inconsistência no log de ações*/
  /*Esse recurso foi feito para zerar o array de modalidade quando realizar uma requisição com sucesso*/
  useEffect(() => {
    setUpdateModalidades([]);
  }, [controlRequest, economicGroupId]);

  /*Foi adicionada uma consulta a API de Get Modalidades para servir de base do objeto que seria exibido em tela com as informações das modalidades, 
  essa function serve para complementar o OBJ do GE com as modalidades resultantes da consulta para caso ele não possua todas as modalidades registradas no banco*/
  function handlerCompleteArrayModality(modalidades) {
    const modFaltantes = [];
    const mod = modalityLimits;

    for (const modalidade of modalidades) {
      var newObj = {
        GroupModalityId: modalidade.Id,
        ActiveErp: null,
        ActivePortal: null,
        LimitErp: null,
        LimitPortal: null,
        LimitIs: null,
        MaximumDuration: null,
        MinimumRate: null,
        MinimumPremium: null,
        Name: null,
      };

      if (mod && mod.length > 0) {
        const index = mod.findIndex(
          (obj) => obj.GroupModalityId === modalidade.Id
        );
        index === -1
          ? modFaltantes.push(newObj)
          : modFaltantes.push(mod[index]);
      } else {
        modFaltantes.push(newObj);
      }
    }
    if (modFaltantes.length > 0) {
      setModalities([...modFaltantes]);
    }
  }

  function updateArrayValue(array, groupId, key, value) {
    // Find the object in the array with the corresponding group_modality_id
    const objIndex = array.findIndex((obj) => obj.GroupModalityId === groupId);

    // If the object is found, update the value of the desired key and set _updated to true
    if (objIndex !== -1) {
      array[objIndex][key] = value;
    } else {
      // If the object does not exist, create a new object with the group_modality_id and the specified key and value
      const newObj = {
        GroupModalityId: groupId,
        ActiveErp: null,
        ActivePortal: null,
        LimitErp: null,
        LimitPortal: null,
        LimitIs: null,
        MaximumDuration: null,
        MinimumRate: null,
        MinimumPremium: null,
        Name: null,
      };
      newObj[key] = value;

      // Add the new object to the array
      array.push(newObj);
    }

    // Return the updated array
    return array;
  }

  function updateArrayModality(groupId, key, value) {
    const objModalidadesUpdated = updateArrayValue(
      modalities,
      groupId,
      key,
      value
    );
    const updateModalidadesUpdated = updateArrayValue(
      updateModalidades,
      groupId,
      key,
      value
    );

    setModalities([...objModalidadesUpdated]);
    setUpdateModalidades([...updateModalidadesUpdated]);

    updatePost("modalityLimits", updateModalidadesUpdated);
  }

  return (
    <BoxContent title="Limites por Grupos de Modalidade" hasBackground={false}>
      <Box sx={{ background: "#465584" }} borderRadius={1} py={2.5} my={3}>
        <Grid container columns={7} columnSpacing={2}>
          <HeaderCell weight={1.5}>Grupo de Modalidade</HeaderCell>
          <HeaderCell weight={1}>Limite de Crédito</HeaderCell>
          <HeaderCell weight={1}>Limite Máximo de IS</HeaderCell>
          <HeaderCell weight={1}>Vigência Máxima</HeaderCell>
          <HeaderCell weight={1}>Prêmio Mínimo</HeaderCell>
          <HeaderCell weight={1}>Taxa de Risco</HeaderCell>
          <HeaderCell weight={0.5}>Ativar</HeaderCell>
        </Grid>
      </Box>

      {!loading ? (
        <Box display="flex" flexDirection="column" gap="25px" width={1}>
          {modalidadesExibicao
          .filter(modalidade =>  ![4, 5, 6].includes(modalidade.Id))
          .map((modalidade, index) => (
            <ModalityGroupRow
              key={index}
              modalidade={modalidade}
              modalidadeValues={
                modalities
                  ? modalities[
                      modalities?.findIndex(
                        (obj) => obj.GroupModalityId === modalidade.Id
                      )
                    ]
                  : 0
              }
              minimumPremiumGE={minimumPremiumGE}
              handleValidation={handleValidation}
              getErrorProps={getErrorProps}
              fieldId={modalidade.Id}
              updateArrayModality={updateArrayModality}
            />
          ))}
        </Box>
      ) : (
        <Skeleton
          width="100%"
          height="580px"
          animation="wave"
          variant="rectangular"
        />
      )}
    </BoxContent>
  );
};

ModalityGroupLimits.propTypes = {
  modalityLimits: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  economicGroupId: PropTypes.number,
  updatePost: PropTypes.func,
  handleValidation: PropTypes.func,
  getErrorProps: PropTypes.func,
};

export default ModalityGroupLimits;
