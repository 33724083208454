import { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  Box,
  Skeleton,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Collapse,
  Select,
  MenuItem,
  InputLabel,
  Stack,
} from "@mui/material";
import Pagination from "@mui/material/Pagination";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BoxContent from "../../../components/BoxContent";
import useStyles from "./styles";
import { formatCurrency } from "../../../utils/formatFunction";
import usePrincipalClient from "../../../clients/PrincipalClient";
import DownloadButton from "../../../components/DownloadButton";

const PrincipalExpireReport = () => {
  const principalClient = usePrincipalClient();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showToastError, setShowToastError] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState(10);
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  const lastIndex = currentPage * recordsPerPage;
  const firstIIndex = lastIndex - recordsPerPage;
  const records = data.slice(firstIIndex, lastIndex);
  const npage = Math.ceil(data.length / recordsPerPage);
  const searchData =
    search !== ""
      ? data.filter((item) => item.DocumentNumber.includes(search))
      : records;

  const options = [
    { value: "5", label: "5" },
    { value: "10", label: "10" },
    { value: "15", label: "15" },
    { value: "20", label: "20" },
    { value: "Todos", label: "Todos" },
  ];

  useEffect(() => {
    setTimeout(() => {
      const fetchPrincipal = async () => {
        try {
          const client = principalClient();
          const response = await client.getPrincipalExpirings();
          setData(response.data.Result);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching modalities:", error);

          const descriptions = error.response.data.Messages.map(
            (message) => message.Description
          );
          var errorMessage = descriptions.join("\n");

          toast.error(errorMessage, {
            autoClose: 5000,
          });
        }
      };
      fetchPrincipal();
    }, 2000);
  }, [principalClient]);

  useEffect(() => {
    if (showToastError) {
      toast.error("Favor revisar o formulário.", {
        autoClose: 5000,
        onClose: () => setShowToastError(false),
      });
    }
  }, [showToastError]);

  const handleChange = (e, p) => {
    setCurrentPage(p);
  };

  const handleSelectChange = (e) => {
    if (e.target.value === "Todos") {
      setSelectedOption(e.target.value);
      setRecordsPerPage(data.length);
    } else {
      setSelectedOption(e.target.value);
      setRecordsPerPage(e.target.value);
    }
  };

  const handleDownloadPrincipalExpiring = async () => {
    try {
      const client = principalClient();
      const response = await client.getPrincipalExpiringsDocument();
      const url = `data:text/xlsx;base64,${response.data.Result[0].File}`;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "Guardian - Relatório de Tomadores a Expirar.xlsx"
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      let errorMessage = "Falha ao buscar os dados.";
      const descriptions = error.response.data.Messages.map(
        (message) => message.Description
      );
      errorMessage = descriptions.join("\n");
      toast.error({
        render: errorMessage,
        type: toast.TYPE.ERROR,
        autoClose: 5000,
        pauseOnFocusLoss: false,
      });
    }
  };

  return (
    <Grid container display="flex" flexDirection="column" p="40px">
      <Grid
        width={1}
        container
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Typography variant="titleSection" fontWeight={600}>
          Relatórios - Tomadores a expirar/expirados
        </Typography>
        <DownloadButton
          title="Clique aqui para baixar o relatório"
          onDownload={handleDownloadPrincipalExpiring}
        />
      </Grid>
      {loading ? (
        <Stack gap={4} marginTop={4}>
          <Skeleton
            width="100%"
            height="180px"
            animation="wave"
            variant="rectangular"
          />
          <Skeleton
            width="100%"
            height="580px"
            animation="wave"
            variant="rectangular"
          />
        </Stack>
      ) : (
        <>
          <Grid width={1} container flexDirection="row" mt="20px">
            <BoxContent title="Buscar Tomador">
              <Box
                width={1}
                style={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                  paddingTop: "40px",
                }}
              >
                <TextField
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Documento do Tomador"
                  label={
                    <SearchIcon style={{ marginLeft: 15, fontSize: 30 }} />
                  }
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  type="number"
                  step="0"
                  style={{ width: "300px" }}
                ></TextField>
              </Box>
            </BoxContent>
          </Grid>
          <Grid
            display="flex"
            container
            flexDirection="row"
            mt="20px"
            mb="10px"
          >
            <Grid width={1} xs={1} item>
              <Select
                labelId="select-label"
                id="select"
                value={selectedOption}
                onChange={handleSelectChange}
                style={{
                  height: "2rem",
                  width: "4.5rem",
                }}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid display="flex" width={1} xs={10} item justifyContent="center">
              <Pagination
                count={npage}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              ></Pagination>
            </Grid>

            <Grid
              display="flex"
              width={1}
              xs={1}
              item
              justifyContent="flex-end"
            >
              <InputLabel>
                <b>{data.length} </b>Registros
              </InputLabel>
            </Grid>
          </Grid>
          <Grid width={1}>
            <TableContainer component={Paper}>
              <Table
                aria-label="collapsible table"
                className={classes.tomadorTable}
              >
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>
                      <Typography variant="span" className={classes.itemTitle}>
                        Nome
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="span" className={classes.itemTitle}>
                        Documento
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="span" className={classes.itemTitle}>
                        Limite de Crédito
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="span" className={classes.itemTitle}>
                        Exposição
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="span" className={classes.itemTitle}>
                        Taxa de Risco
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="span" className={classes.itemTitle}>
                        Prêmio Mínimo
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="span" className={classes.itemTitle}>
                        Validade
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="span" className={classes.itemTitle}>
                        Status
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {searchData.map((row) => (
                    <Row key={row.I4proId} row={row} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}

      <ToastContainer pauseOnHover={false} draggable={false} autoClose={0} />
    </Grid>
  );

  function Row({ row }) {
    const [open, setOpen] = useState(false);

    return (
      <>
        <TableRow
          sx={{ "& > *": { borderBottom: "unset" } }}
          className={classes.tomadorRow}
        >
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
              disabled={!row.EconomicGroups?.length}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            <Typography variant="span" className={classes.itemTitle}>
              {row.Name}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="description" className={classes.itemTitle}>
              {row.DocumentNumber}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="description" className={classes.itemTitle}>
              {row.Limits.Limit ? formatCurrency(row.Limits.Limit) : "-"}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="description" className={classes.itemTitle}>
              {formatCurrency(row.Limits.Exposure)}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="description" className={classes.itemTitle}>
              {row.Limits.MinimumRate} %
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="description" className={classes.itemTitle}>
              {row.Limits.MinimumPremium
                ? formatCurrency(row.Limits.MinimumPremium)
                : "-"}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="description" className={classes.itemTitle}>
              {row.Limits.Validity && row.Limits.Validity.split("-")[0] > 1
                ? new Date(row.Limits.Validity).toLocaleDateString("pt-BR", {
                    timeZone: "UTC",
                  })
                : "-"}
            </Typography>
          </TableCell>
          <TableCell>{row.I4ProStatus}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            {row.EconomicGroups != null && row.EconomicGroups.length > 0 ? (
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <TableContainer component={Paper}>
                    <Table
                      aria-label="collapsible table"
                      className={classes.tomadorTable}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className={`${classes.smallCell} ${classes.mediumCell}`}
                          />
                          <TableCell>
                            <Typography
                              variant="span"
                              className={classes.itemTitle}
                            >
                              Grupos Econômicos
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {row.EconomicGroups.map((eg) => (
                          <TableRow key={eg.EconomicGroupId}>
                            <TableCell
                              className={`${classes.smallCell} ${classes.mediumCell}`}
                            />
                            <TableCell component="th" scope="row">
                              <Typography
                                variant="span"
                                className={classes.itemDescription}
                              >
                                {eg.Name}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Collapse>
            ) : (
              ""
            )}
          </TableCell>
        </TableRow>
      </>
    );
  }
};

export default PrincipalExpireReport;
